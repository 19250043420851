import { FaAngleLeft, FaAngleRight } from 'react-icons/fa'

import Button from '../common/Button/Button.component'
import CarouselCard from './CarouselCard'
import { FiChevronLeft } from 'react-icons/fi'
import Icon from '../common/Icon/Icon.component'
import { IconButton } from '@mui/material'
import { MNSCompletePlan } from '../../interface/quotes/mNonSupplementPlans/MNSCompletePlan'
import { MSPlan } from '../../interface/quotes/mSupplementPlans/MSPlan'
import { MedicareQuotesType } from '../../enums/MedicareQuoteTypeEnum'
import ModalComponent from '../common/Modal/Modal.component'
import React from 'react'
import ThankYouModal from '../DashboardPage/Modals/ThankYouModal'
import { isUndefined } from 'lodash'
import { observer } from 'mobx-react'
import snackbarStore from '../../datastore/SnackbarStore'
import styled from '@emotion/styled'
import theme from '../../global/theme'
import userActionStore from '../../datastore/UserActionStore'

interface CarouselHeaderProps {
  className: string
  handleScroll?: () => void
  handleBack: () => void
  loading?: boolean
  cardDetailsInOrder: MNSCompletePlan | (MSPlan | undefined)[] | undefined
  planType?: string
}

const Container = styled.div`
  overflow-x: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
  scroll-behavior: smooth;
`
const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 0fr;
  width: 100%;
`

const LeftBufferSpace = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  min-width: 350px;
  height: 100%;
  display: flex;
  background-color: ${theme.colors.white};
  z-index: 100;
  p {
    display: none;
  }

  @media screen and (max-width: 900px) {
    min-width: 250px;
  }

  @media screen and (max-width: 650px) {
    position: static;
    min-width: 150px;
    flex-direction: column;
    p {
      flex: 1;
      display: flex;
      align-items: center;
      padding: 10px;
      font-size: 16px;
      color: ${theme.colors.textGrey};
    }
  }
`

const CardsWrapper = styled.div<{ hasThreeCards: boolean }>`
  display: flex;
  flex-direction: row;
  padding: 0px 0px 0px 0px;
  margin-left: 350px;
  @media screen and (max-width: 1450px) {
    ${(props) =>
      props.hasThreeCards &&
      `
    padding: 0px 50px 0px 0px;
  `}
  }
  @media screen and (max-width: 1091px) {
    padding: 0px 50px 0px 0px;
  }
  @media screen and (max-width: 900px) {
    margin-left: 200px;
    padding: 0px 0px 0px 50px;
  }
  @media screen and (max-width: 650px) {
    margin-left: 0px;
    padding: 0px;
  }
`
const LeftSlide = styled.div`
  width: 60px;
  height: 100%;
  position: absolute;
  left: 300px;
  background-color: ${theme.colors.white};
  border: 2px solid ${theme.colors.borderColor};
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 900px) {
    left: 200px;
  }
  @media screen and (max-width: 650px) {
    display: none;
  }
`

const RightSlide = styled.div`
  width: 60px;
  height: 100%;
  position: absolute;
  left: calc(100vw - 60px);
  background-color: ${theme.colors.white};
  border: 2px solid ${theme.colors.borderColor};
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 650px) {
    display: none;
  }
`

const CarouselHeader: React.FC<CarouselHeaderProps> = ({
  className,
  handleScroll,
  handleBack,
  loading = false,
  cardDetailsInOrder,
  planType,
}) => {
  const [thankYouModalActive, setThankYouModalActive] =
    React.useState<boolean>(false)

  const [dimensions, setDimensions] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth,
  })

  const debounce = <A extends unknown[]>(
    callback: (...args: A) => unknown,
    msDelay: number
  ) => {
    let timer: NodeJS.Timeout | undefined

    return (...args: A) => {
      clearTimeout(timer)

      timer = setTimeout(() => {
        timer = undefined
        callback(...args)
      }, msDelay)
    }
  }

  React.useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      })
    }, 100)

    window.addEventListener('resize', debouncedHandleResize)

    return () => {
      window.removeEventListener('resize', debouncedHandleResize)
    }
  })

  const scrolledContainer = document.getElementsByClassName(className)
  const handleLeftScroll = () => {
    scrolledContainer[0].scrollBy(-300, 0)
  }
  const handleRightScroll = () => {
    scrolledContainer[0].scrollBy(300, 0)
  }

  const handleOnEnroll = () => {
    setThankYouModalActive(true)
  }

  const handleRemove = (id: string) => {
    const numberOfCards =
      userActionStore.comparePlanCards.compareCardsData.length

    if (numberOfCards > 2) userActionStore.removeCard(id)
    else {
      snackbarStore.set({
        snackbarMessage: 'A minimum of 2 plans is required for comparison',
        snackbarOpen: true,
        snackbarType: 'error',
      })
    }
  }

  const hasThreeCards =
    userActionStore.comparePlanCards.compareCardsData.length === 3
      ? true
      : false

  React.useEffect(() => {
    if (userActionStore.getEnrollmentRequestPlan().planId !== '') {
      const filteredPlan = userActionStore
        .getComparePlanCards()
        .compareCardsData.find((plan) => {
          return userActionStore.getEnrollmentRequestPlan().planId === plan.id
        })
      if (!isUndefined(filteredPlan)) {
        filteredPlan.enrollRequestStatus = true
      }
    }
  }, [userActionStore.getEnrollmentRequestPlan().planId])

  const showSlider =
    userActionStore.comparePlanCards.compareCardsData.length === 2
      ? dimensions.width > 1090
        ? false
        : true
      : dimensions.width > 1450
      ? false
      : true

  const getEnrollRequestStatus = (iterator: any) => {
    if (cardDetailsInOrder && iterator) {
      if (planType !== MedicareQuotesType.MEDICARE_SUPPLEMENT) {
        cardDetailsInOrder = cardDetailsInOrder as MNSCompletePlan
        const enrollReqPlan = cardDetailsInOrder.plans.find(
          (plan) => plan?.id === iterator.sunfirePlanId
        )
        if (enrollReqPlan) return enrollReqPlan.enrollRequested
        else return iterator.enrollRequested
      } else {
        cardDetailsInOrder = cardDetailsInOrder as MSPlan[] | undefined[]
        const enrollReqPlan = cardDetailsInOrder.find(
          (plan) => plan?.id === iterator.id
        )
        if (enrollReqPlan) return enrollReqPlan.enrollRequested
        else return iterator.enrollRequested
      }
    }
  }

  return (
    <Container className={className} onScroll={handleScroll}>
      {thankYouModalActive && (
        <ModalComponent setOpen={() => setThankYouModalActive(false)}>
          <ThankYouModal setOpen={() => setThankYouModalActive(false)} />
        </ModalComponent>
      )}

      <Wrapper>
        <LeftBufferSpace>
          <Button variant='text' width='100px' onClick={handleBack}>
            <span style={{ display: 'flex', alignItems: 'center' }}>
              <Icon childComponent={<FiChevronLeft />} />
              Back
            </span>
          </Button>

          <p>Swipe left to view plan cards for comparison.</p>
          {showSlider && (
            <LeftSlide>
              <IconButton onClick={handleLeftScroll}>
                <FaAngleLeft />
              </IconButton>
            </LeftSlide>
          )}
          {showSlider && (
            <RightSlide>
              <IconButton onClick={handleRightScroll}>
                <FaAngleRight />
              </IconButton>
            </RightSlide>
          )}
        </LeftBufferSpace>
        <CardsWrapper hasThreeCards={hasThreeCards}>
          {userActionStore.comparePlanCards.compareCardsData.map((iterator) => (
            <CarouselCard
              id={iterator.id}
              key={iterator.id}
              name={iterator.name}
              carrier={iterator.carrier}
              premium={iterator.premium}
              rating={iterator.rating}
              onEnroll={handleOnEnroll}
              onRemove={handleRemove}
              enrollRequestStatus={getEnrollRequestStatus(iterator)}
              planType={iterator.planType}
              enrollmentRedirectURL={iterator.enrollmentRedirectURL}
              msPlanType={iterator.medicareSupplementPlanType}
              cardLoading={loading}
              moop={iterator.moop}
            />
          ))}
        </CardsWrapper>
      </Wrapper>
    </Container>
  )
}

export default observer(CarouselHeader)
