import { capitalize, isEmpty, isNull, isUndefined } from 'lodash'
import {
  capitalizeFirstCharacter,
  convertNameToTitleCase,
  getApiErrorMessage,
} from '../../../utils/StringUtils'

import { ActionContext } from '../../../cra'
import ApiManager from '../../../api/ApiManager'
import Button from '../../common/Button/Button.component'
import Card from '../../common/Card/Card.component'
import CarrierLogo from '../../common/CarrierLogo'
import { County } from '../../../interface/CountyInterface'
import DateField from '../../common/DateField'
import Filter from 'bad-words'
import { HttpMethods } from '../../../enums/HttpMethodsEnum'
import InputField from '../../common/InputField/InputField.component'
import Label from '../../common/Label/Label'
import MaskedInput from 'react-maskedinput'
import RadioGroup from '../../common/RadioGroup/RadioGroup.component'
import React from 'react'
import RouteConstants from '../../../constants/RouteConstants'
import SectionRow from '../../common/SectionRow'
import SecureComponent from '../../../pages/common/SecureComponent'
import Select from '../../common/Select/Select.component'
import { SelectChangeEvent } from '@mui/material'
import SelectMonthYear from '../../common/Select/SelectMonthYear.component'
import SkeletonCard from '../../DashboardPage/SkeletonCard'
import { SnackbarTypes } from '../../../enums/SnackbarTypesEnum'
import StringConstants from '../../../constants/StringConstants'
import UrlConstants from '../../../constants/UrlConstants'
import ValidationUtils from '../../../utils/validation/ValidationUtils'
import ZipUtils from '../../../utils/ZipUtils'
import currentPlanStore from '../../../datastore/CurrentPlanStore'
import customerStore from '../../../datastore/CustomerStore'
import dayjs from 'dayjs'
import { getDateWithinApprovedDOBRange } from '../../../utils/CommonUtils'
import mPartDPlansFilterStore from '../../../datastore/medicareQuotes/MPartPlanFilterQuotesStore'
import medicareAdvantageFilterQuoteStore from '../../../datastore/medicareQuotes/MedicareAdvantageFilterQuoteStore'
import moment from 'moment'
import msPlanFiltersStore from '../../../datastore/medicareQuotes/MSupplementPlanFiltersStore'
import { observer } from 'mobx-react'
import snackbarStore from '../../../datastore/SnackbarStore'
import styled from '@emotion/styled'
import theme from '../../../global/theme'
import { useNavigate } from 'react-router-dom'
import userActionStore from '../../../datastore/UserActionStore'
import { withRouter } from '../../common/WithRouter/WithRouter.component'

interface DataProps {
  spouseOptIn: boolean
  firstName: string
  lastName: string
  dateOfBirth: string
  currentMedicarePartBDateSelf: string | null
  spouseFirstName: string
  spouseLastName: string
  spouseDateOfBirth: string
  currentMedicarePartBDateSpouse: string | null
  gender: string | null
  spouseGender: string | null
  quotingToolVersionId: string
  mailingOptIn: boolean
  mailingStreet: string
  mailingCity: string
  mailingCounty: string
  mailingState: string
  mailingPostalCode: string
  postalCode: string
  county: string
  countyFips: string
  state: string
  city: string
  street: string
  email: string
  phone: string
  additionalPhone: string | null
}

interface ProfileProps {
  isSpouse: boolean
}

const Container = styled.div`
  width: 100%;
  padding: 20px 0px;
`

const CurrentPlanWrapper = styled.div`
  div {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    padding: 5px 10px;
    border-radius: 4px;
    img {
      width: 80px;
      margin-right: 40px;
    }
    h3 {
      font-size: 18px;
      color: ${theme.colors.textGrey};
    }

    p {
      font-size: 14px;
      display: flex;
      align-items: center;
      text-align: left;
      width: 100%;
      font-weight: 400;
      color: ${theme.colors.textGrey};
      span {
        margin-left: 10px;
        margin-right: 10px;
        font-weight: 600;
        font-size: 16px;
      }
    }
  }

  .contrastBack {
    background-color: ${theme.colors.contrastPrimary};
    justify-content: space-between;
  }
`

const DisableText = styled.div`
  font-size: 14px;
  margin-left: 5px;
  color: ${theme.colors.secondary};
`

const Profile: React.FC<ProfileProps> = (props) => {
  const filter = new Filter()
  const navigate = useNavigate()
  const [firstName, setFirstName] = React.useState('')
  const [lastName, setLastName] = React.useState('')
  const [dob, setDOB] = React.useState('')
  const [gender, setGender] = React.useState<string>('')
  const [isEnrolled, setIsEnrolled] = React.useState(true)
  const [effectiveDate, setEffectiveDate] = React.useState('')
  const [monthlyPremium, setMonthlyPremium] = React.useState('')
  const [rxCoverageIncluded, setRxCoverageIncluded] = React.useState<
    null | boolean
  >(null)
  const [planName, setPlanName] = React.useState('')
  const [carrier, setCarrier] = React.useState('')
  const genderOptions = ['Male', 'Female']
  const [state, setState] = React.useState({
    firstNameError: false,
    firstNameHelperText: '',
    lastNameError: false,
    lastNameHelperText: '',
    dobError: false,
    dobHelperText: '',
    effectiveDateError: false,
    effectiveDateHelperText: '',
    anticipatedEndDateError: false,
    anticipatedEndDateHelperText: '',
    loading: false,
    buttonLoading: false,
    addrZipError: false,
    addrZipHelperText: '',
    addrStateError: false,
    addrStateHelperText: '',
    addrCountyError: false,
    addrCityError: false,
    addrCityHelperText: '',
    addrStreetError: false,
    addrStreetHelperText: '',
    emailError: false,
    emailHelperText: '',
    phoneError: false,
    phoneHelperText: '',
    alternatePhoneError: false,
    alternatePhoneHelperText: '',
  })
  const [shouldResetEffectiveDate, setShouldResetEffectiveDate] =
    React.useState(false)
  const { trackCurrentPage } = React.useContext(ActionContext)
  const yearsTo = dayjs().year()
  const defaultYear = yearsTo - 67
  const currentDate = dayjs(dob || new Date())
  const defaultDate = currentDate.year(defaultYear).format('YYYY-MM-DD')
  const [addrZip, setAddrZip] = React.useState('')
  const [addrState, setAddrState] = React.useState('')
  const [optCounties, setOptCounties] = React.useState<County[]>([])
  const [addrCounty, setAddrCounty] = React.useState<County>({} as County)
  const [addrCity, setAddrCity] = React.useState('')
  const [addrStreet, setAddrStreet] = React.useState('')
  const [email, setEmail] = React.useState('')
  const [phone, setPhone] = React.useState('')
  const [alternatePhone, setAlternatePhone] = React.useState('')
  const isButtonDisabled =
    customerStore.isMAQuotesProcessing ||
    customerStore.isMSQuotesProcessing ||
    customerStore.isMPartDQuotesProcessing

  const resetInputErrors = () => {
    setState({
      ...state,
      addrZipError: false,
      addrZipHelperText: '',
      addrCityError: false,
      addrCountyError: false,
      addrStateError: false,
      addrStreetError: false,
    })
  }

  const fetchCustomerDetails = async (
    doNotSuppress = true
  ): Promise<boolean> => {
    let success = false
    await ApiManager.makeApiCallWithAuthentication(
      UrlConstants.GET_CUSTOMER_DETAILS.USECASE,
      HttpMethods.GET_METHOD
    )
      .then((response) => {
        if (response.status === 200) {
          const data = response.data.data
          customerStore.set(data)
          success = true
          return success
        } else throw response
      })
      .catch((err) => {
        snackbarStore.set({
          snackbarMessage: getApiErrorMessage('fetch customer details'),
          snackbarOpen: true,
          snackbarType: SnackbarTypes.ERROR,
        })
        return success
      })
    return success
  }

  const fetchInfoSteps = () => {
    ApiManager.makeApiCallWithAuthentication(
      UrlConstants.GET_INFO_STEPS.USECASE,
      HttpMethods.GET_METHOD
    )
      .then((res) => {
        const infoStepsArray = res.data.data as string[]
        customerStore.setInfoSteps(infoStepsArray)
      })
      .catch((err) => {
        snackbarStore.set({
          snackbarMessage: getApiErrorMessage('fetch user progress'),
          snackbarOpen: true,
          snackbarType: SnackbarTypes.ERROR,
        })
      })
  }

  const GetCountiesCityAndStateFromZip = (zipCode: string) => {
    const zipCodeCache = userActionStore.zipCodeCache.find((iterator) => {
      if (iterator.code === zipCode) return iterator
    })

    if (!zipCodeCache) {
      // console.log('Fetch not from zipCodeCache')
      ZipUtils.GetCountyCityAndStateForZip(zipCode)
        .then(
          (response: { counties: County[]; city: string; state: string }) => {
            if (!isEmpty(response.city)) {
              if (
                zipCode !== customerStore.postalCode ||
                (isEmpty(addrCity) && isEmpty(customerStore.city))
              ) {
                setAddrCity(response.city)
                userActionStore.setZipCodeCache({
                  code: zipCode,
                  city: response.city,
                })
              } else {
                userActionStore.setZipCodeCache({
                  code: zipCode,
                  city: isEmpty(addrCity) ? customerStore.city : addrCity,
                })
              }
            } else {
              if (isEmpty(addrCity)) setAddrCity('')
            }

            if (!isEmpty(response.state)) {
              if (zipCode !== customerStore.postalCode || isEmpty(addrState))
                setAddrState(response.state)
              userActionStore.setZipCodeCache({
                code: zipCode,
                city: addrCity,
                state: response.state,
              })
            } else {
              if (isEmpty(addrState)) setAddrState('')
            }
            if (!isEmpty(response.counties)) {
              if (zipCode !== customerStore.postalCode || isEmpty(optCounties))
                setOptCounties(response.counties)
              let county: County
              if (
                zipCode !== customerStore.postalCode ||
                isEmpty(customerStore.county) ||
                isEmpty(customerStore.countyFips)
              ) {
                county = response.counties[0]
                setAddrCounty(county)
              } else {
                const tempCounty = response.counties.find(
                  (county) => county.countyFips === customerStore.countyFips
                )
                county = {
                  countyName: tempCounty ? tempCounty.countyName : '',
                  countyFips: customerStore.countyFips,
                }
                setAddrCounty(county)
              }
              resetInputErrors()

              userActionStore.setZipCodeCache({
                code: zipCode,
                city: addrCity,
                state: addrState,
                county: county,
                optCounties: response.counties,
              })
            } else {
              setOptCounties([])
              setAddrCity('')
              setAddrState('')
              setAddrCounty({} as County)
              setAddrStreet('')
              setState({ ...state, addrCountyError: true })
            }
          }
        )
        .catch((error: any) => {
          snackbarStore.set({
            snackbarMessage:
              error.data &&
              error.data.length > 0 &&
              !isEmpty(error.data[0]) &&
              error.data[0].reason
                ? error.data[0].reason
                : StringConstants.UI_ERROR_MSG_ON_COUNTY_NOT_RETRIEVED_FROM_ZIP,
            snackbarOpen: true,
            snackbarType: SnackbarTypes.ERROR,
          })
          setAddrCity('')
          setAddrState('')
          setAddrCounty({} as County)
          setAddrStreet('')
          setOptCounties([])
          setState({
            ...state,
            addrZipError: true,
            addrZipHelperText:
              StringConstants.UI_ERROR_MSG_FOR_INVALID_ZIP_CODE,
            addrCityError: false,
            addrCountyError: false,
            addrStateError: false,
            addrStreetError: false,
          })
        })
    } else {
      // console.log('Fetch from zipCodeCache')
      setOptCounties(zipCodeCache.optCounties || [])
      setAddrState(zipCodeCache.state || '')
      setAddrCounty(zipCodeCache.county || ({} as County))
      if (isEmpty(addrCity)) setAddrCity(zipCodeCache.city || '')
      resetInputErrors()
    }
  }

  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    fetchCustomerDetails()
    fetchInfoSteps()
  }, [])

  React.useEffect(() => {
    trackCurrentPage(
      props.isSpouse
        ? 'Profile - Spouse/Partner Profile'
        : 'Profile - Profile Page'
    )
    currentPlanStore.set(
      props.isSpouse
        ? customerStore.currentPlanSpouse || null
        : customerStore.currentPlanCustomer || null
    )
    setFirstName(
      props.isSpouse ? customerStore.spouseFirstName : customerStore.firstName
    )
    setLastName(
      props.isSpouse ? customerStore.spouseLastName : customerStore.lastName
    )
    setDOB(
      props.isSpouse
        ? customerStore.spouseDateOfBirth.substring(0, 10)
        : customerStore.dateOfBirth.substring(0, 10)
    )
    setGender(
      props.isSpouse
        ? capitalizeFirstCharacter(customerStore.spouseGender)
        : capitalizeFirstCharacter(customerStore.gender)
    )
    setAddrZip(customerStore.postalCode)
    setAddrState(customerStore.state)
    if (!isEmpty(customerStore.county) && !isEmpty(customerStore.countyFips)) {
      setAddrCounty({
        countyName: customerStore.county,
        countyFips: customerStore.countyFips,
      })
    }
    setAddrCity(customerStore.city)
    setAddrStreet(customerStore.street)
    setEmail(customerStore.email)
    setPhone(customerStore.phone.replace('+1', ''))
    customerStore.additionalPhone &&
      setAlternatePhone(customerStore.additionalPhone.replace('+1', ''))
  }, [
    props.isSpouse,
    customerStore.firstName,
    customerStore.spouseFirstName,
    customerStore.lastName,
    customerStore.spouseLastName,
    customerStore.dateOfBirth,
    customerStore.spouseDateOfBirth,
    customerStore.currentMedicarePartBDateSelf,
    customerStore.currentMedicarePartBDateSpouse,
    customerStore.currentPlanCustomer,
    customerStore.currentPlanSpouse,
    customerStore.postalCode,
    customerStore.state,
    customerStore.county,
    customerStore.countyFips,
    customerStore.city,
    customerStore.street,
    customerStore.email,
    customerStore.phone,
    customerStore.additionalPhone,
  ])

  React.useEffect(() => {
    if (!isEmpty(customerStore.postalCode)) {
      GetCountiesCityAndStateFromZip(customerStore.postalCode)
      if (optCounties[0]?.countyName !== '' && !isEmpty(customerStore.county)) {
        setAddrCounty({
          countyFips: customerStore.countyFips,
          countyName: customerStore.county,
        })
      }
    }
  }, [customerStore.postalCode, customerStore.county])

  React.useEffect(() => {
    window.scroll({ top: 0, behavior: 'smooth' })
  }, [])

  React.useEffect(() => {
    setMonthlyPremium(currentPlanStore.monthlyPremium)
  }, [currentPlanStore.monthlyPremium])

  React.useEffect(() => {
    setRxCoverageIncluded(currentPlanStore.rxIncluded)
  }, [currentPlanStore.rxIncluded])

  React.useEffect(() => {
    if (!isEmpty(currentPlanStore.planType))
      setPlanName(currentPlanStore.planType)
  }, [currentPlanStore, currentPlanStore.planType])

  React.useEffect(() => {
    let carrier = ''
    if (!isEmpty(currentPlanStore.provider)) {
      carrier = currentPlanStore.provider
    }
    setCarrier(carrier)
  }, [currentPlanStore, currentPlanStore.provider])

  const validateFirstName = (firstName: string) => {
    const firstNameError = !ValidationUtils.validateFirstName(firstName)
    setState({
      ...state,
      firstNameError,
      firstNameHelperText: isEmpty(firstName)
        ? StringConstants.UI_ERROR_MSG_FOR_EMPTY_FIRST_NAME
        : firstNameError
        ? StringConstants.UI_ERROR_MSG_FOR_INVALID_FIRST_NAME
        : '',
    })
  }
  const handleFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const firstName = isEmpty(e.target.value)
      ? ''
      : filter.clean(e.target.value).replaceAll('*', '')
    validateFirstName(firstName)
    setFirstName(firstName)
  }

  const validateLastName = (lastName: string) => {
    const lastNameError = !ValidationUtils.validateLastName(lastName)
    setState({
      ...state,
      lastNameError,
      lastNameHelperText: isEmpty(lastName)
        ? StringConstants.UI_ERROR_MSG_FOR_EMPTY_LAST_NAME
        : lastNameError
        ? StringConstants.UI_ERROR_MSG_FOR_INVALID_LAST_NAME
        : '',
    })
  }
  const handleLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const lastName = isEmpty(e.target.value)
      ? ''
      : filter.clean(e.target.value).replaceAll('*', '')
    validateLastName(lastName)
    setLastName(lastName)
  }

  const validateDOB = (dob: string) => {
    const dobError = !ValidationUtils.validateDOB(dob)
    setState({
      ...state,
      dobError,
      dobHelperText: isEmpty(dob)
        ? StringConstants.UI_ERROR_MSG_FOR_EMPTY_DOB
        : dobError
        ? StringConstants.UI_ERROR_MSG_FOR_INVALID_DOB
        : '',
    })
    return dobError
  }
  const handleDOBChange = (e: any) => {
    if (e) {
      const givenDOB = dayjs(e.$d).format('YYYY-MM-DD')
      if (validateDOB(givenDOB)) setDOB(givenDOB)
    }
  }
  const handleInputDOB = (e: any) => {
    if (e) {
      const givenDOB = dayjs(e.$d).format('YYYY-MM-DD')
      if (e.$y >= 1000) {
        const finalDate = getDateWithinApprovedDOBRange(e)
        validateDOB(finalDate)
        setDOB(finalDate)
      } else validateDOB(givenDOB)
    }
  }

  const validateEffectiveDate = (effectiveDate: string) => {
    const effectiveDateError =
      !ValidationUtils.validateEffectiveDate(effectiveDate)
    setState({
      ...state,
      effectiveDateError,
      effectiveDateHelperText: effectiveDateError
        ? isEmpty(effectiveDate)
          ? StringConstants.UI_ERROR_MSG_FOR_EMPTY_PART_B_EFFECTIVE_DATE
          : StringConstants.UI_ERROR_MSG_FOR_INVALID_PART_B_EFFECTIVE_DATE
        : '',
    })
  }
  const handleEffectiveDateChange = (inputDate: string) => {
    validateEffectiveDate(inputDate)
    setIsEnrolled(true)
    setEffectiveDate(inputDate)
  }

  const handleIsEnrolledChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let effectiveDateError =
      !ValidationUtils.validateEffectiveDate(effectiveDate)
    if (e.target.value === 'Yes') {
      setIsEnrolled(true)
      setPlanName(currentPlanStore.planType)
      setCarrier(currentPlanStore.provider)
    } else {
      setIsEnrolled(false)
      setEffectiveDate('')
      setShouldResetEffectiveDate(true)
      setPlanName('')
      setCarrier('')
      effectiveDateError = false
    }
    setState({
      ...state,
      effectiveDateError,
      effectiveDateHelperText: effectiveDateError
        ? isEmpty(effectiveDate)
          ? StringConstants.UI_ERROR_MSG_FOR_EMPTY_PART_B_EFFECTIVE_DATE
          : StringConstants.UI_ERROR_MSG_FOR_INVALID_PART_B_EFFECTIVE_DATE
        : '',
    })
  }

  const handleGenderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setGender(e.target.value)
  }

  const validateAddrZip = (addrZip: string) => {
    let addrZipError = !ValidationUtils.validateZipcode(addrZip)
    setState({
      ...state,
      addrZipHelperText: addrZipError
        ? StringConstants.UI_ERROR_MSG_FOR_INVALID_ZIP_CODE
        : '',
      addrZipError,
    })
    return addrZipError
  }

  const handleAddrZipChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value && e.target.value.length < 6) {
      let hasError = validateAddrZip(e.target.value)
      if (/(^\d{0,5}$)/.test(e.target.value)) {
        setAddrZip(e.target.value)
      }
      if (!hasError) {
        GetCountiesCityAndStateFromZip(e.target.value)
      } else {
        setAddrCity('')
        setAddrState('')
        setAddrCounty({} as County)
        setAddrStreet('')
        setOptCounties([])
      }
    } else if (!e.target.value) {
      setAddrZip(e.target.value)
    }
  }

  const validateAddrState = (addrState: string) => {
    let addrStateError = !ValidationUtils.validateState(addrState)

    setState({
      ...state,
      addrStateHelperText: addrStateError
        ? StringConstants.UI_ERROR_MSG_FOR_INVALID_STATE
        : '',
      addrStateError,
    })
  }

  const handleAddrStateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    validateAddrState(e.target.value)
    setAddrState(e.target.value)
  }

  const validateAddrCounty = (addrCounty: string) => {
    const addrCountyError = isEmpty(addrCounty)

    setState({
      ...state,
      addrCountyError: addrCountyError,
    })
  }

  const handleAddrCountyChange = (e: SelectChangeEvent<string>) => {
    validateAddrCounty(e.target.value)
    const county = optCounties.find(
      (county) => county.countyName === e.target.value
    )
    if (!isUndefined(county)) setAddrCounty(county)
  }

  const validateAddrCity = (addrCity: string) => {
    let addrCityError = !ValidationUtils.validateCity(addrCity)

    setState({
      ...state,
      addrCityHelperText: addrCityError
        ? StringConstants.UI_ERROR_MSG_FOR_INVALID_CITY
        : '',
      addrCityError,
    })
  }

  const handleAddrCityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value.replace(/\w+/g, capitalize)
    validateAddrCity(value)
    setAddrCity(value)
  }

  const validateAddrStreet = (addrStreet: string) => {
    let addrStreetError = !ValidationUtils.validateStreet(addrStreet)

    setState({
      ...state,
      addrStreetHelperText: addrStreetError
        ? StringConstants.UI_ERROR_MSG_FOR_INVALID_STREET
        : '',
      addrStreetError,
    })
  }

  const handleAddrStreetChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    validateAddrStreet(e.target.value)
    setAddrStreet(e.target.value)
  }

  const validateEmail = (email: string) => {
    const emailError = !ValidationUtils.validateEmail(email)
    setState({
      ...state,
      emailError,
      emailHelperText: isEmpty(email)
        ? StringConstants.UI_ERROR_MSG_FOR_EMPTY_EMAIL
        : emailError
        ? StringConstants.UI_ERROR_MSG_FOR_INVALID_EMAIL
        : '',
    })
  }

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    validateEmail(e.target.value)
    setEmail(e.target.value)
  }

  const validatePhone = (phone: string) => {
    var phoneError = true
    if (phone === '') {
      setState({
        ...state,
        phoneError: false,
        phoneHelperText: '',
      })
    } else {
      let conditionalStateData = {}
      if (
        state.alternatePhoneError &&
        state.alternatePhoneHelperText ===
          StringConstants.UI_ERROR_MSG_FOR_ALTERNATE_PHONE_SAME_AS_PHONE &&
        phone !== alternatePhone
      ) {
        conditionalStateData = {
          alternatePhoneError: false,
          alternatePhoneHelperText: '',
        }
      }
      ValidationUtils.verifyPhoneNumber(phone)
        .then((response: any) => {
          if (response.data.data.status === 'valid') {
            phoneError = false
            setState({
              ...state,
              phoneError: phoneError || phone === alternatePhone,
              phoneHelperText: isEmpty(phone)
                ? StringConstants.UI_ERROR_MSG_FOR_EMPTY_PHONE
                : phoneError
                ? StringConstants.UI_ERROR_MSG_FOR_INVALID_PHONE
                : phone === alternatePhone
                ? StringConstants.UI_ERROR_MSG_FOR_PHONE_SAME_AS_ALTERNATE_PHONE
                : '',
            })
          }
        })
        .catch((err: Error) => {
          console.log(err)
        })
      setState({
        ...state,
        phoneError: phoneError || phone === alternatePhone,
        phoneHelperText: isEmpty(phone)
          ? ''
          : phoneError
          ? StringConstants.UI_ERROR_MSG_FOR_INVALID_PHONE
          : phone === alternatePhone
          ? StringConstants.UI_ERROR_MSG_FOR_PHONE_SAME_AS_ALTERNATE_PHONE
          : '',
        ...conditionalStateData,
      })
    }
  }

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let phone = e.target.value.replace(/[- )( _]/g, '')
    validatePhone(phone)
    setPhone(phone)
  }

  const validateAlternatePhone = (alternatePhone: string) => {
    var alternatePhoneError = true
    if (alternatePhone === '') {
      setState({
        ...state,
        alternatePhoneError: false,
        alternatePhoneHelperText: '',
      })
    } else {
      let conditionalStateData = {}
      if (
        state.phoneError &&
        state.phoneHelperText ===
          StringConstants.UI_ERROR_MSG_FOR_PHONE_SAME_AS_ALTERNATE_PHONE &&
        phone !== alternatePhone
      ) {
        conditionalStateData = {
          phoneError: false,
          phoneHelperText: '',
        }
      }
      ValidationUtils.verifyPhoneNumber(alternatePhone)
        .then((response: any) => {
          if (response.data.data.status === 'valid') {
            alternatePhoneError = false
            setState({
              ...state,
              alternatePhoneError:
                alternatePhoneError || phone === alternatePhone,
              alternatePhoneHelperText: alternatePhoneError
                ? StringConstants.UI_ERROR_MSG_FOR_INVALID_ALTERNATE_PHONE
                : phone === alternatePhone
                ? StringConstants.UI_ERROR_MSG_FOR_ALTERNATE_PHONE_SAME_AS_PHONE
                : '',
              ...conditionalStateData,
            })
          }
        })
        .catch((err: Error) => {
          console.log(err)
        })
      setState({
        ...state,
        alternatePhoneError: alternatePhoneError || phone === alternatePhone,
        alternatePhoneHelperText: alternatePhoneError
          ? StringConstants.UI_ERROR_MSG_FOR_INVALID_ALTERNATE_PHONE
          : phone === alternatePhone
          ? StringConstants.UI_ERROR_MSG_FOR_ALTERNATE_PHONE_SAME_AS_PHONE
          : '',
        ...conditionalStateData,
      })
    }
  }

  const handleAlternatePhoneChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    let alternatePhone = e.target.value.replace(/[- )( _]/g, '')
    validateAlternatePhone(alternatePhone)
    setAlternatePhone(alternatePhone)
  }

  const handleSave = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    customerStore.setIsQuotesPullAPiCalled(false)
    customerStore.setIsProfileUpdating(true)
    let hasError = false
    let isQuotesRelatedDetailsChanged = false
    let isEffectiveDateChanged = false,
      isDOBChanged = false,
      isGenderChanged = false
    e.preventDefault()
    if (isEmpty(firstName)) {
      setState({
        ...state,
        firstNameError: true,
        firstNameHelperText: StringConstants.UI_ERROR_MSG_FOR_EMPTY_FIRST_NAME,
      })
    }
    if (isEmpty(lastName)) {
      setState({
        ...state,
        lastNameError: true,
        lastNameHelperText: StringConstants.UI_ERROR_MSG_FOR_EMPTY_LAST_NAME,
      })
    }
    if (isEmpty(dob)) {
      setState({
        ...state,
        dobError: true,
        dobHelperText: StringConstants.UI_ERROR_MSG_FOR_EMPTY_DOB,
      })
    }
    if (!isEmpty(dob)) {
      validateDOB(dob)
    }
    if (isEmpty(addrZip)) {
      hasError = true
      setState({
        ...state,
        addrZipError: true,
        addrZipHelperText: StringConstants.UI_ERROR_MSG_FOR_INVALID_ZIP_CODE,
      })
    }
    if (isEmpty(addrState)) {
      hasError = true
      setState({
        ...state,
        addrStateError: true,
        addrStateHelperText: StringConstants.UI_ERROR_MSG_FOR_INVALID_STATE,
      })
    }
    if (isEmpty(addrCounty) || isEmpty(optCounties)) {
      hasError = true
      setState({
        ...state,
        addrCountyError: true,
      })
    }
    if (isEmpty(addrCity)) {
      hasError = true
      setState({
        ...state,
        addrCityError: true,
        addrCityHelperText: StringConstants.UI_ERROR_MSG_FOR_INVALID_CITY,
      })
    }
    if (isEmpty(addrStreet)) {
      hasError = true
      setState({
        ...state,
        addrStreetError: true,
        addrStreetHelperText: StringConstants.UI_ERROR_MSG_FOR_INVALID_STATE,
      })
    }
    if (isEmpty(email)) {
      hasError = true
      setState({
        ...state,
        emailError: true,
        emailHelperText: StringConstants.UI_ERROR_MSG_FOR_INVALID_EMAIL,
      })
    }
    if (isEmpty(phone)) {
      hasError = true
      setState({
        ...state,
        phoneError: true,
        phoneHelperText: StringConstants.UI_ERROR_MSG_FOR_INVALID_PHONE,
      })
    }
    if (
      state.phoneHelperText !==
        StringConstants.UI_ERROR_MSG_FOR_PHONE_SAME_AS_ALTERNATE_PHONE &&
      !state.alternatePhoneError &&
      !isEmpty(alternatePhone) &&
      phone === alternatePhone
    ) {
      hasError = true
      setState({
        ...state,
        alternatePhoneError: true,
        alternatePhoneHelperText:
          StringConstants.UI_ERROR_MSG_FOR_ALTERNATE_PHONE_SAME_AS_PHONE,
      })
    }

    if (
      !(
        state.firstNameError ||
        state.lastNameError ||
        state.dobError ||
        state.effectiveDateError ||
        hasError ||
        state.addrZipError ||
        state.addrStateError ||
        state.addrCountyError ||
        state.addrCityError ||
        state.addrStreetError ||
        state.emailError ||
        state.phoneError ||
        state.alternatePhoneError
      )
    ) {
      let data: Partial<DataProps> = {}
      if (props.isSpouse) {
        if (firstName !== customerStore.spouseFirstName)
          data.spouseFirstName = firstName
        if (lastName !== customerStore.spouseLastName)
          data.spouseLastName = lastName

        if (gender !== capitalizeFirstCharacter(customerStore.spouseGender))
          data.spouseGender = gender.toLowerCase()
        if (
          dob !== '' &&
          (isEmpty(customerStore.spouseDateOfBirth) ||
            (!isEmpty(customerStore.spouseDateOfBirth) &&
              dob !== customerStore.spouseDateOfBirth.substring(0, 10)))
        )
          data.spouseDateOfBirth = dob
      } else {
        if (firstName !== customerStore.firstName) data.firstName = firstName
        if (lastName !== customerStore.lastName) data.lastName = lastName
        if (gender.toLowerCase() !== customerStore.gender) {
          isGenderChanged = true
          data.gender = gender.toLowerCase()
        }

        if (
          dob !== '' &&
          (isEmpty(customerStore.dateOfBirth) ||
            (!isEmpty(customerStore.dateOfBirth) &&
              dob !== customerStore.dateOfBirth.substring(0, 10)))
        ) {
          isDOBChanged = true
          data.dateOfBirth = dob
        }
      }
      if (addrZip !== customerStore.postalCode) {
        data.mailingOptIn = true
        data.postalCode = addrZip
        data.mailingPostalCode = addrZip
        isQuotesRelatedDetailsChanged = true
      }
      if (addrState !== customerStore.state) {
        data.mailingOptIn = true
        data.state = addrState
        data.mailingState = addrState
      }
      if (addrCounty.countyName !== customerStore.county) {
        data.county = addrCounty.countyName
        data.countyFips = addrCounty.countyFips
        if (isUndefined(data.postalCode)) isQuotesRelatedDetailsChanged = true
      }
      if (addrCounty.countyName !== customerStore.mailingCounty) {
        data.mailingOptIn = true
        data.mailingCounty = addrCounty.countyName
      }
      if (addrCity !== customerStore.city) {
        data.city = addrCity
      }
      if (addrCity !== customerStore.mailingCity) {
        data.mailingOptIn = true
        data.mailingCity = addrCity
      }
      if (addrStreet !== customerStore.street) {
        data.street = addrStreet
      }
      if (addrStreet !== customerStore.mailingStreet) {
        data.mailingOptIn = true
        data.mailingStreet = addrStreet
      }
      if (email !== customerStore.email) {
        data.email = email
      }
      if ('+1' + phone !== customerStore.phone) {
        data.phone = '+1' + phone
      }
      if (
        '+1' + alternatePhone !== customerStore.additionalPhone &&
        !(isEmpty(alternatePhone) && customerStore.additionalPhone === null)
      ) {
        data.additionalPhone = isEmpty(alternatePhone)
          ? null
          : '+1' + alternatePhone
      }

      if (!isEmpty(data)) {
        data.quotingToolVersionId = process.env.REACT_APP_VERSION_ID
        setState({
          ...state,
          buttonLoading: true,
        })
        ApiManager.makeApiCallWithAuthentication(
          UrlConstants.UPDATE_CUSTOMER_DETAILS.USECASE,
          HttpMethods.PATCH_METHOD,
          data
        )
          .then(async (response) => {
            if (response.status === 200) {
              if ((await fetchCustomerDetails(false)) === true) {
                userActionStore.setZipCodeCache({
                  code: addrZip,
                  city: addrCity,
                  county: addrCounty,
                })
                snackbarStore.set({
                  snackbarMessage: StringConstants.SNACKBAR_SAVE_SUCCESS_MSG,
                  snackbarOpen: true,
                  snackbarType: SnackbarTypes.SUCCESS,
                })
                if (isQuotesRelatedDetailsChanged) {
                  customerStore.setQuotePulledStatusMA(true)
                  if (
                    !isEmpty(customerStore.dateOfBirth) &&
                    !isEmpty(customerStore.gender)
                  ) {
                    customerStore.setQuotePulledStatusMS(true)
                    msPlanFiltersStore.clearMSPlanFilterStore()
                  }
                  customerStore.setQuotePulledStatusMPartD(true)
                  customerStore.setIsRidersApplied(true)
                  medicareAdvantageFilterQuoteStore.clearStore()
                  mPartDPlansFilterStore.clearStore()
                }
                // if (
                //   isEffectiveDateChanged &&
                //   !isEmpty(customerStore.dateOfBirth) &&
                //   !isEmpty(customerStore.gender)
                // ) {
                //   msPlanFiltersStore.clearMSPlanFilterStore()
                //   medicareAdvantageFilterQuoteStore.clearStore()
                //   mPartDPlansFilterStore.clearStore()
                //   customerStore.setQuotePulledStatusMS(true)
                // } else if (
                //   isEffectiveDateChanged &&
                //   isEmpty(customerStore.dateOfBirth) &&
                //   isEmpty(customerStore.gender)
                // ) {
                //   medicareAdvantageFilterQuoteStore.clearStore()
                //   mPartDPlansFilterStore.clearStore()
                // } else

                if (!isEmpty(customerStore.dateOfBirth) && isDOBChanged) {
                  msPlanFiltersStore.clearMSPlanFilterStore()
                  customerStore.setQuotePulledStatusMS(true)
                } else if (!isEmpty(customerStore.gender) && isGenderChanged) {
                  msPlanFiltersStore.clearMSPlanFilterStore()
                  customerStore.setQuotePulledStatusMS(true)
                }
                snackbarStore.set({
                  snackbarMessage: StringConstants.SNACKBAR_SAVE_SUCCESS_MSG,
                  snackbarOpen: true,
                  snackbarType: SnackbarTypes.SUCCESS,
                })
              }
            } else throw response
          })
          .catch((err) => {
            snackbarStore.set({
              snackbarMessage: getApiErrorMessage('update customer details'),
              snackbarOpen: true,
              snackbarType: SnackbarTypes.ERROR,
            })
          })
          .finally(() => {
            customerStore.setIsProfileUpdating(false)
            setState({
              ...state,
              loading: false,
              buttonLoading: false,
            })
          })
      } else {
        customerStore.setIsProfileUpdating(false)
        snackbarStore.set({
          snackbarMessage: StringConstants.SNACKBAR_NO_DATA_TO_SAVE_MSG,
          snackbarOpen: true,
          snackbarType: SnackbarTypes.WARNING,
        })
      }
    }
  }

  return !state.loading ? (
    <Container>
      <SectionRow row>
        <InputField
          error={state.firstNameError}
          helperText={state.firstNameHelperText}
          width='100%'
          label='First Name'
          placeholder={props.isSpouse ? 'First name' : 'Enter your first name'}
          value={convertNameToTitleCase(firstName)}
          onChange={handleFirstNameChange}
        />
        <InputField
          error={state.lastNameError}
          helperText={state.lastNameHelperText}
          width='100%'
          label='Last Name'
          placeholder={props.isSpouse ? 'Last name' : 'Enter your last name'}
          value={convertNameToTitleCase(lastName)}
          onChange={handleLastNameChange}
        />
      </SectionRow>

      <SectionRow row>
        <DateField
          bottomPadding={false}
          label='Date of Birth'
          format='MM/DD/YYYY'
          value={dayjs(dayjs(dob).format('YYYY-MM-DD'))}
          minDate={dayjs(
            (moment().year() - 120).toString().substring(0, 2) + '00-01-01'
          )}
          maxDate={dayjs(moment().format('YYYY-MM-DD'))}
          defaultCalendarMonth={dayjs(
            moment(defaultDate).startOf('day').format('YYYY-MM-DD')
          )}
          error={state.dobError}
          errorText={state.dobHelperText}
          readonly={false}
          onAccept={handleDOBChange}
          onChange={handleInputDOB}
        />
      </SectionRow>
      <SectionRow row>
        <RadioGroup
          bottomPadding={false}
          radioButtons={genderOptions}
          row={true}
          onChange={handleGenderChange}
          value={gender}
          radioGroupName='Gender'
          label='Gender'
        />
      </SectionRow>
      {/* {!props.isSpouse && (
        <SectionRow row>
          <div style={{ paddingBottom: '20px' }}>
            <Label>Password</Label>
            <Button
              onClick={() => navigate(RouteConstants.RESET_PASSWORD)}
              width='100%'
            >
              Change Password
            </Button>
          </div>
        </SectionRow>
      )} */}
      {/* <SectionRow row>
        <RadioGroup
          label='Currently enrolled in Medicare Part B?'
          radioButtons={['Yes', 'No']}
          row={true}
          onChange={handleIsEnrolledChange}
          value={isEnrolled ? 'Yes' : 'No'}
          radioGroupName='Currently enrolled in Medicare Part B?'
        />
      </SectionRow> */}
      {/* <SectionRow row>
        <SelectMonthYear
          label='Medicare Part B effective date?'
          value={effectiveDate}
          resetDate={shouldResetEffectiveDate}
          handleChange={handleEffectiveDateChange}
          showValidationError={state.effectiveDateError}
          setValidationErrorTrue={() => {
            setIsEnrolled(true)
            setState({
              ...state,
              effectiveDateError: true,
            })
          }}
          setValidationErrorFalse={() => {
            setShouldResetEffectiveDate(false)
            setState({
              ...state,
              effectiveDateError: false,
            })
          }}
        />
      </SectionRow>
      {planName !== '' && (
        <SectionRow>
          <Label>Current Plan</Label>x
          <Card
            cardType='readOnly'
            borderColor={theme.colors.primary}
            borderWidth='2px'
          >
            <CurrentPlanWrapper>
              <div>
                <CarrierLogo carrier={carrier} useAltLogo={true} />
                <h3>{planName}</h3>
              </div>
              <div className='contrastBack'>
                <p>
                  Monthly Premium <span>${monthlyPremium}</span>
                </p>
                {!isNull(rxCoverageIncluded) && (
                  <p>
                    Rx Coverage {rxCoverageIncluded === false && 'not'} included
                  </p>
                )}
              </div>
            </CurrentPlanWrapper>
          </Card>
        </SectionRow>
      )} */}

      {!props.isSpouse && (
        <>
          <SectionRow row>
            <InputField
              error={state.emailError}
              helperText={state.emailHelperText}
              width='100%'
              label='Email'
              placeholder='Enter your email address'
              value={email}
              onChange={handleEmailChange}
            />
          </SectionRow>
          <SectionRow row>
            <InputField
              error={state.phoneError}
              helperText={state.phoneHelperText}
              width='100%'
              label='Phone number'
              value={phone}
              onChange={handlePhoneChange}
              inputComponent={MaskedInput}
              inputProps={{ mask: '(111) 111-1111' }}
              shrink={phone ? true : undefined}
              placeholder='(123) 456-7890'
            />
            <InputField
              error={state.alternatePhoneError}
              helperText={state.alternatePhoneHelperText}
              width='100%'
              label='Alternate phone number'
              value={alternatePhone}
              onChange={handleAlternatePhoneChange}
              inputComponent={MaskedInput}
              inputProps={{ mask: '(111) 111-1111' }}
              shrink={alternatePhone ? true : undefined}
              placeholder='(123) 456-7890'
            />
          </SectionRow>

          <SectionRow>
            <InputField
              error={state.addrStreetError}
              helperText={state.addrStreetHelperText}
              width='100%'
              label='Street'
              placeholder='Your Street'
              value={addrStreet}
              onChange={handleAddrStreetChange}
            />
          </SectionRow>
          <SectionRow row>
            <InputField
              error={state.addrZipError}
              helperText={state.addrZipHelperText}
              label='Zip Code'
              value={addrZip}
              onChange={handleAddrZipChange}
              fullWidth
            />
            <Select
              bottomPadding={false}
              error={state.addrCountyError}
              label='County'
              options={optCounties.map((county) => county.countyName)}
              value={
                optCounties.length === 1
                  ? optCounties[0].countyName
                  : addrCounty.countyName
              }
              onChange={handleAddrCountyChange}
            />
          </SectionRow>
          <SectionRow row>
            <InputField
              readOnly
              error={state.addrStateError}
              helperText={state.addrStateHelperText}
              label='State'
              value={addrState}
              onChange={handleAddrStateChange}
              fullWidth
            />
            <InputField
              error={state.addrCityError}
              helperText={state.addrCityHelperText}
              width='100%'
              label='City'
              placeholder='Enter your city'
              value={addrCity}
              onChange={handleAddrCityChange}
            />
          </SectionRow>
        </>
      )}

      <SectionRow row>
        <Button
          width='100%'
          color='secondary'
          variant='contained'
          onClick={handleSave}
          loading={state.loading}
          disabled={state.loading}
        >
          Save Changes
        </Button>
      </SectionRow>
      {/* <SectionRow>
        {isButtonDisabled && (
          <DisableText>{StringConstants.DISABLE_BUTTON_MSG}</DisableText>
        )}
      </SectionRow> */}
    </Container>
  ) : (
    <SkeletonCard />
  )
}

export default SecureComponent(withRouter(observer(Profile)))
