const StringConstants = {
  COOKIE_TOKEN: 'token',
  REMEMBERED_USERNAME: 'username',
  PLAN_RECOMMENDATION_SCORES: 'plan_recommendation_scores',
  API_ERROR_MSG_FOR_CUSTOMER_AUTH_FAILURE: 'Authentication Failed',
  API_ERROR_MSG_FOR_INVALID_CURRENT_PASSWORD: 'Invalid Current Password',
  API_ERROR_MSG_FOR_SAME_PASSWORD_RESET:
    'Cannot reuse same password, please use a different one',
  API_ERROR_MSG_FOR_CHANGE_PASSWORD:
    'Cannot update password, please check the inputs',
  API_ERROR_MSG_FOR_RESET_PASSWORD: 'Password could not be reset',
  UI_ERROR_MSG_FOR_CUSTOMER_AUTH_FAILURE: 'Username/Password is incorrect',
  UI_ERROR_MSG_SERVER_NOT_RESPONDING:
    'Server not responding. Please try again later.',
  UI_ERROR_MSG_FOR_INVALID_USERNAME: 'Please enter a valid username',
  UI_ERROR_MSG_FOR_INVALID_PASSWORD: 'Please enter a valid password',
  UI_ERROR_MSG_FOR_EMPTY_FIRST_NAME: 'First Name should have some value',
  UI_ERROR_MSG_FOR_INVALID_FIRST_NAME: 'Please enter a valid First Name',
  UI_ERROR_MSG_FOR_EMPTY_FULL_NAME: 'Full Name should have some value',
  UI_ERROR_MSG_FOR_INVALID_FULL_NAME: 'Please enter a valid Full Name',
  UI_ERROR_MSG_FOR_INVALID_SSN: 'Please enter a valid social security number.',
  UI_ERROR_MSG_FOR_EMPTY_LAST_NAME: 'Last Name should have some value',
  UI_ERROR_MSG_FOR_INVALID_LAST_NAME: 'Please enter a valid Last Name',
  UI_ERROR_MSG_FOR_EMPTY_DOB: 'Date of Birth should have some value',
  UI_ERROR_MSG_FOR_INVALID_DOB: 'Please enter a valid Date of Birth',
  UI_ERROR_MSG_FOR_EMPTY_PART_B_EFFECTIVE_DATE:
    'Please select a Medicare Part B effective date',
  UI_ERROR_MSG_FOR_INVALID_PART_B_EFFECTIVE_DATE:
    'Please enter a valid Medicare Part B effective date',
  UI_ERROR_MSG_FOR_EMPTY_ANTICIPATED_END_DATE:
    'Anticipated end date should have some value',
  UI_ERROR_MSG_FOR_INVALID_ANTICIPATED_END_DATE:
    'Anticipated End Date must be later than Effective Date',
  UI_ERROR_MSG_ON_COUNTY_NOT_RETRIEVED_FROM_ZIP:
    'Could not retrieve county from zip code',
  UI_ERROR_MSG_FOR_INVALID_ZIP_CODE: 'Please enter a valid zip code',
  UI_ERROR_MSG_FOR_INVALID_STATE: 'Please enter a valid state',
  UI_ERROR_MSG_FOR_INVALID_CITY: 'Please enter a valid city',
  UI_ERROR_MSG_FOR_INVALID_STREET: 'Please enter a valid street',
  UI_ERROR_MSG_FOR_EMPTY_EMAIL: 'Email should have some value',
  UI_ERROR_MSG_FOR_INVALID_EMAIL: 'Please enter a valid email',
  UI_ERROR_MSG_FOR_EMPTY_PHONE: 'Please provide a valid phone number',
  UI_ERROR_MSG_FOR_INVALID_PHONE: 'Please provide a valid phone number',
  UI_ERROR_MSG_FOR_PHONE_SAME_AS_ALTERNATE_PHONE:
    'Your phone number cannot be the same as the alternate phone number provided below. Please provide another phone number.',
  UI_ERROR_MSG_FOR_INVALID_ALTERNATE_PHONE:
    'Please provide a valid phone number',
  UI_ERROR_MSG_FOR_ALTERNATE_PHONE_SAME_AS_PHONE:
    'Alternate phone number cannot be the same as your phone number provided above. Please provide another phone number.',
  UI_ERROR_MSG_FOR_PREFERRED_PHONE_SAME_AS_PHONE:
    'Preferred phone number cannot be the same as your phone number shown above. Please provide another phone number.',
  UI_ERROR_MSG_FOR_GENDER_NOT_SELECTED: 'A gender should be selected',
  UI_ERROR_MSG_FOR_EMPTY_DRUG_QUANTITY: 'Drug quantity should have some value',
  UI_ERROR_MSG_FOR_INVALID_DRUG_QUANTITY: 'Please enter a valid drug quantity',
  UI_ERROR_MSG_FOR_DRUG_QUANTITY_MORE_THAN_LIMIT:
    'Please enter a quantity less than 100',
  UI_ERROR_MSG_FOR_EMPTY_DRUG_FREQUENCY: 'A frequency should be selected',
  UI_ERROR_MSG_FOR_INVALID_CURRENT_PASSWORD: 'Incorrect current password',
  UI_ERROR_MSG_FOR_REUSING_CURRENT_PASSWORD:
    'Cannot reuse the current password',
  UI_ERROR_MSG_FOR_INVALID_CHANGE_PASSWORD: 'Password could not be changed',
  UI_ERROR_MSG_FOR_RESET_PASSWORD_FAIL: 'Password could not be reset',
  SNACKBAR_DATA_FETCH_SUCCESS_MSG: 'Data fetch successful',
  SNACKBAR_SAVE_SUCCESS_MSG: 'Save successful',
  SNACKBAR_UPDATE_SUCCESS_MSG: 'Update successful',
  SNACKBAR_NO_DATA_TO_SAVE_MSG: 'Nothing to save yet',
  SNACKBAR_ERROR_ON_INPUTS: 'Please correct the errors in the input fields.',
  SNACKBAR_DELETE_SUCCESS_MSG: 'Deletion successful',
  UI_ERROR_MSG_FOR_EMPTY_HEALTH_QUESTION_COVERAGE:
    'Coverages should have some value',
  UI_ERROR_MSG_FOR_MORE_CHARACTERS_THAN_SF_LIMIT:
    'Field must not have more than 255 characters',

  //* Tooltip Messages *//
  //*===Filters===*/
  /* Common */
  TOOLTIP_MSG_MAXIMUM_MONTHLY_PREMIUM:
    'Maximum Monthly Premium indicates the maximum amount you will pay each month in premiums.',
  TOOLTIP_MSG_MAXIMUM_ANNUAL_DEDUCTIBLE:
    'Maximum Annual Deductible indicates the most you will pay annually before your coverage takes effect.',
  TOOLTIP_MSG_MAXIMUM_OUT_OF_POCKET:
    'Maximum Out-of-pocket refers to the amount you pay yearly on Medicare-covered services.' +
    ' Your annual costs will not exceed this amount.',
  TOOLTIP_MSG_PLAN_BENEFITS:
    'Plan benefits are additional benefits you want plans in your search to provide.',
  /* MA Tab Filters */
  TOOLTIP_MSG_MA_STAR_RATING:
    'Medicare star ratings rank the quality and performance of Medicare Advantage in your area.' +
    ' Ratings range from 1 to 5 stars based on the plan’s safety, preventive care, member experience, and customer service.',
  TOOLTIP_MSG_MA_RX_COVERAGE:
    'Rx coverage is a prescription drug benefit that helps cover the cost of prescription medications.',
  TOOLTIP_MSG_MA_CARRIERS:
    'Carriers refer to the insurance companies that offer Medicare Advantage plans in your area.',
  TOOLTIP_MSG_MA_DRUGS_COVERED:
    'Drugs Covered allows you to select your prescribed medications to see how each plan covers them.',
  TOOLTIP_MSG_MA_Physician_COVERED:
    'Doctors Covered allows you to select your doctors to see how each plan covers them.',
  TOOLTIP_MSG_MA_PLAN_TYPE:
    'Choose the type of Medicare Advantage plan you wish to see.' +
    ' HMO plans usually have lower costs and a more restrictive network.' +
    ' PPO plans typically have a higher premium with more flexibility.',
  /* MS Tab Filters */
  TOOLTIP_MSG_MS_TOBACCO_CONSUMPTION:
    'If you’ve used tobacco in the last 12 months, please select yes. The use of tobacco may impact your monthly Medicare Supplement premium.',
  TOOLTIP_MSG_MS_PART_B_EFFECTIVE_DATE:
    'The Effective Date filter displays the date on which your coverage will begin.' +
    ' By default, this will be set to your Medicare Part B effective date as provided if in the future.' +
    ' If your Part B effective date has passed, this date will be set to the first of the following month.' +
    ' Any edits to the value in the filter will not change your Part B effective date.',
  TOOLTIP_MSG_MS_MAXIMUM_ESTIMATED_ANNUAL_COST:
    'Maximum Estimated Annual Cost refers to the maximum amount you pay yearly on Medicare-covered services.',
  TOOLTIP_MSG_MS_PLAN_TYPES:
    'Plan type filters the type of Medicare Supplement plans you want to view.',
  TOOLTIP_MSG_MS_AM_BEST_RATING:
    'AM Best ratings are recognized worldwide as an indicator of insurance companies’ financial strength and creditworthiness.' +
    ' Companies with a higher rating are more likely to pay claims on time and without error.',
  TOOLTIP_MSG_MS_CARRIERS:
    'Carriers refer to the insurance companies that offer Medicare Supplement plans in your area.',
  /* End of MS Tab Filters */
  TOOLTIP_MSG_MS_HH_DISCOUNT:
    'This carrier offers a percentage discount on your monthly Medicare Supplement premium when two people from the same household are enrolled in the same Medicare Supplement insurance company.',
  MS_TAB_SUBHEADING_FOR_MA_LEAD: 'We need more information from you',
  /* M Part D Tab Filters  */
  TOOLTIP_MSG_MPARTD_STAR_RATING:
    'Medicare star ratings rank the quality and performance of Medicare Part D plans in your area.' +
    ' Ratings range from 1 to 5 stars and are based on the plan’s drug coverage, member experience, and customer service.',
  TOOLTIP_MSG_PDP_PLAN_DEDUCTIBLE:
    'Deductible indicates the amount you must reach before moving into the next phase of Medicare drug coverage.',
  TOOLTIP_MSG_MPARTD_CARRIERS:
    'Carriers refer to the insurance companies that offer Medicare PartD plans in your area.',
  TOOLTIP_MSG_MPARTD_DRUGS_COVERED:
    'Drugs Covered allows you to select the drugs you require your plan to cover.',
  //*===End of Filters===*/
  //*---Compare Plans---*//
  /* MS Plans’ Comparison  */
  TOOLTIP_MSG_ESTIMATED_ANNUAL_COST:
    'The maximum amount you pay each year on Medicare-covered services.',
  TOOLTIP_MSG_MONTHLY_PREMIUM: 'Cost of the plan per month.',
  TOOLTIP_MSG_ANNUAL_DEDUCTIBLE:
    'Your out-of-pocket cost before your health coverage kicks in.',
  TOOLTIP_MSG_MSPLAN_HH_DISCOUNT:
    'The percentage discount provided by an insurance carrier when two people from the same household enroll in the same Medigap company. ',
  TOOLTIP_MSG_MSPLAN_MONTHLY_PREMIUM_WITH_HH_DISCOUNT:
    'Cost of the plan per month with household discount applied.',
  TOOLTIP_MSG_CARRIER: 'Health insurance company.',
  TOOLTIP_MSG_MSPLAN_TYPE:
    'The type of Medicare Supplement plan from the 12 available options.',
  TOOLTIP_MSG_PREMIUM_MONTHLY: 'The cost of the plan each month for 12 months.',
  TOOLTIP_MSG_PREMIUM_QUARTERLY:
    'The cost of the plan if you make four payments annually.',
  TOOLTIP_MSG_PREMIUM_SEMI_ANNUALLY:
    'The cost of the plan if you make a twice-yearly payment.',
  TOOLTIP_MSG_PREMIUM_ANNUALLY:
    'The cost of the plan if you make a one-time annual payment.',
  TOOLTIP_MSG_AM_BEST_OUTLOOK:
    'The AM Best outlook indicates the financial strength and creditworthiness of insurance companies.',
  TOOLTIP_MSG_AM_BEST_RATING:
    'AM Best ratings are recognized worldwide as an indicator of insurance companies’ financial strength and creditworthiness.' +
    ' Companies with a higher rating are more likely to pay claims on time and without error.',
  TOOLTIP_MSG_HOSPITALIZATION_EXPENSES:
    'Review your hospital benefits and understand the costs associated with a hospital stay.',
  TOOLTIP_MSG_SNF_EXPENSES:
    'Review your Skilled Nursing Facility benefits and understand the costs associated with a nursing facility stay.',
  TOOLTIP_MSG_MEDICAL_EXPENSES:
    'Understand your costs to treat or prevent injury or illness.',
  TOOLTIP_MSG_CLINICAL_LAB_SERVICES:
    'Understand your costs for laboratory procedures like bloodwork, stool samples, etc.',
  TOOLTIP_MSG_DME_EXPENSES:
    'Expenses related to medical equipment that is intended to last for more than one use, often several years.',
  TOOLTIP_MSG_HOME_HEALTH_CARE_SERVICES_EXPENSES:
    'Understand your expenses related to Home Health Care services.Costs include skilled nursing care copayments, occupational therapy, medication management, and more.',
  TOOLTIP_MSG_BLOOD_SERVICES_EXPENSES:
    'Expenses for blood services such as infusion, testing, collection, storage, and more.',
  TOOLTIP_MSG_HOSPICE_CARE_SERVICES_EXPENSES:
    'Understand your expenses related to Hospice care, such as pain management, emotional support, assistance with daily tasks, and more. ',
  /* End of MS Plans’ Comparison  */
  TOOLTIP_MSG_RIDERS:
    'Riders are available to provide additional benefits to your base Medicare Supplement plan. Riders are only available in select areas.' +
    ' Please select a plan below to view its available riders.',

  TOOLTIP_MSG_DEDUCTIBLE:
    'The amount you pay out-of-pocket for covered services before your health plan kicks in.',
  TOOLTIP_MSG_PLAN_NAME: 'Name of the plan.',
  TOOLTIP_MSG_PLAN_YEAR: 'Year of enrollment.',
  TOOLTIP_MSG_STAR_RATING:
    'Medicare star ratings indicate which plans in your area have the highest satisfaction ratings.' +
    ' Star ratings only pertain to Medicare Advantage and Part D plans.',
  TOOLTIP_MSG_SUPPORTS_MAIL_ORDER:
    'Supports drug purchases from Mail Order Pharmacies',
  TOOLTIP_MSG_MAX_OUT_OF_POCKET:
    'An out-of-pocket maximum is a cap, or limit, on the amount of money you have to pay for covered health care services in a plan year.' +
    ' If you meet that limit, your health plan will pay 100% of all covered health care costs for the rest of the plan year.',
  TOOLTIP_MSG_INIT_DRUG_COVERAGE:
    'Begins: immediately if your plan has no deductible.' +
    ' Or, when the prescription payments you have made equal your plans deductible.' +
    ' Your plan pays for a portion of each prescription drug you purchase, as long as that medication is covered under the plan’s formulary (list of covered drugs).' +
    ' You pay the other portion, which is either a copayment (a set dollar amount) or coinsurance (a percentage of the drug’s cost).' +
    ' The amount you pay will depend on the tier level assigned to your drug.' +
    ' This stage ends when the amount spent by you and your plan on your covered drugs adds up to equal the initial coverage limit set by Medicare for that year.' +
    ' In 2022 that limit is $4,430. Your monthly premium payments do not count toward reaching that limit.',
  TOOLTIP_MSG_COINSURANCE_AMT_PREFERRED:
    'Coinsurance is the percentage of costs you pay after you’ve met your deductible.',
  TOOLTIP_MSG_COPAY_AMT_PREFERRED:
    'A copay is a set rate you pay for prescriptions, doctor visits, and other types of care.',
  TOOLTIP_MSG_MOOP_IN_NETWORK:
    'Maximum Out-Of-Pocket costs for in-network services.' +
    ' The MOOP is the limit on annual out-of-pocket expenditures paid by a health plan enrollee for medical services that are covered by a health insurance plan.' +
    ' This refers to the most you’ll pay for copays, coinsurance, and deductibles for in-network covered medical services for the calendar year, not including your prescription drug costs or monthly premium.' +
    ' Once you reach your MOOP, your insurance company pays for 100 percent of your covered medical services.',
  TOOLTIP_MSG_MOOP_IN_AND_OUT_NETWORK:
    'Maximum Out-Of-Pocket” costs for in-network and out-of-network services.' +
    ' The MOOP is the limit on annual out-of-pocket expenditures paid by a health plan enrollee for medical services that are covered by a health insurance plan.' +
    ' This refers to the most you’ll pay for copays, coinsurance, and deductibles for in-network covered medical services for the calendar year, not including your prescription drug costs or monthly premium.' +
    ' Once you reach your MOOP, your insurance company pays for 100 percent of your covered medical services.',
  TOOLTIP_MSG_PRIMARY_DOCTOR_COPAY:
    'Copay for availing the services of Primary Doctor.' +
    ' Copay is a fixed amount you pay for a covered health care service, usually at the time you receive the service.' +
    ' The amount may vary depending on the type of service you receive.',
  TOOLTIP_MSG_SPECIALIST_VISIT_COPAY:
    'Copay for availing the services of a Specialist.' +
    ' Copay is a fixed amount you pay for a covered health care service, usually at the time you receive the service.' +
    ' The amount may vary depending on the type of service you receive.',
  TOOLTIP_MSG_EMERGENCY_CARE_VISIT_COPAY:
    'Copay for availing emergency care services.' +
    ' Copay is a fixed amount you pay for a covered health care service, usually at the time you receive the service.' +
    ' The amount may vary depending on the type of service you receive.',
  TOOLTIP_MSG_DENTAL_OFFICE_VISIT_EXPENSES:
    'An office visit copayment is a fixed dollar amount or a percentage that you pay for each dentist visit or for each dental service provided.',
  TOOLTIP_MSG_DENTAL_CLEANING_EXPENSES:
    'A cleaning copayment is a fixed dollar amount or a percentage that you pay for each cleaning service provided.',
  TOOLTIP_MSG_DENTAL_ORAL_EXAM_EXPENSES:
    'An oral exam copayment is a fixed dollar amount or a percentage that you pay for each oral exam provided.',
  TOOLTIP_MSG_DENTAL_XRAY_EXPENSES:
    'An x-ray copayment is a fixed dollar amount or a percentage that you pay for each x-ray service service provided.',
  TOOLTIP_MSG_DENTAL_RESTORATIVE_SERVICES_EXPENSES:
    'A restorative services copayment is a fixed dollar amount or a percentage that you pay for each dental restorative service provided.',
  TOOLTIP_MSG_VISION_CONTACT_LENSES_EXPENSES:
    'Coverage/copayment for services related to contact lenses.',
  TOOLTIP_MSG_VISION_EYEGLASS_FRAMES_EXPENSES:
    'Coverage/copayment for services related to eyeglass frames.',
  TOOLTIP_MSG_VISION_EYEGLASS_LENSES_EXPENSES:
    'Coverage/copayment for services related to eyeglass lenses.',
  TOOLTIP_MSG_VISION_ROUTINE_EYE_EXAM_EXPENSES:
    'Coverage/copayment for services related to routine eye exam.',
  TOOLTIP_MSG_VISION_OTHER_EXPENSES:
    'Coverage/copayment for other services related to vision.',
  TOOLTIP_MSG_HEARING_AIDS_EXPENSES:
    'Coverage/copayment for services related to hearing aids.',
  TOOLTIP_MSG_HEARING_EXAM_EXPENSES:
    'Coverage/copayment for services related to hearing exams.',
  TOOLTIP_MSG_HEARING_FITTING_EVAL_EXPENSES:
    'Coverage/copayment for services related to fitting evaluation.',
  TOOLTIP_MSG_MCARE_PART_B_CHEMOTHERAPY:
    'Indicates coverage/coinsurance for Chemotherapy most often used to treat cancer.',
  TOOLTIP_MSG_MCARE_PART_B_OTHER_DRUGS:
    'Indicates coverage/coinsurance for Other Part B Drugs.',
  TOOLTIP_MSG_MED_EQUIPMENT_PROSTHETICS_EXPENSES:
    'Indicates coverage/coinsurance for Prosthetics intended to restore the normal functions of the missing body part.',
  TOOLTIP_MSG_MED_EQUIPMENT_DIABETIC_SUPPLIES_EXPENSES:
    'Indicates coverage/coinsurance for Diabetic Supplies.',
  TOOLTIP_MSG_MED_EQUIPMENT_DME_EXPENSES:
    'Indicates coverage/coinsurance for Durable Medical Equipment(DME).' +
    'These are medical equipments that are intended to last for more than one use, and oftentimes are intended to last for months or even years.',
  //* End of Tooltip Messages *//
  API_ERROR_MSG: 'Not able to :message.',
  INTERNAL_SERVER_ERROR: 'Internal Server Error. Please try again later.',
  DEFAULT_PHONE_NUMBER: '(888) 335-8996',
  MA_PLAN_NO_PLANS_FOUND: 'No MA Plans Available',
  MPARTD_PLAN_NO_PLANS_FOUND: 'No PDP Plans Available',
  MS_PLAN_NO_PLANS_FOUND: 'No MS Plans Available',
  PLAN_TECHNICAL_ISSUE_ERROR_MESSAGE: 'Plans not retrieved - Technical error',
  NP_POP_UP_CAROUSEL_MESSAGE:
    'We are gathering your personalized Medicare plans! Hang tight!',
  NP_POP_UP_LOADER_CAROUSEL_CONTENT: [
    '"Elite Insurance Partners has Helped Over 31,000+ Medicare Clients Save Hundreds of Dollars by Comparing Rates Online."',
    '"Our Medicare software will review your information and show the best plans for you online. You will be able to browse through the plans in detail and assess your savings. It’s 100% free!"',
  ],
  AUTO_LOGIN_LOADER_MESSAGE:
    'We are gathering your personalized Medicare plans! Hang tight!',
  AUTO_LOGIN_LOADER_CAROUSEL_CONTENT: [
    '"Elite Insurance Partners has Helped Over 31,000+ Medicare Clients Save Hundreds of Dollars by Comparing Rates Online."',
    '"Our Medicare software will review your information and show the best plans for you online. You will be able to browse through the plans in detail and assess your savings. It’s 100% free!"',
  ],
  INFO_STEPS_CURRENT_PLAN_DESCRIPTION:
    'The Current Plan section displays the details of your plan based on the information you provided.' +
    ' This includes the type of plan you are enrolled in, your plan carrier, your current plan name, and your Part B effective date.' +
    ' You can edit this information to be more accurate if necessary.',
  TOOLTIP_MSG_MCARE_PART_A_COINSURANCE:
    'Your Medicare Part A hospital coinsurance includes up to 60 days of full ' +
    'coverage and 30 days that require a coinsurance per benefit period plus 60 ' +
    'additional lifetime reserve days that can be used consecutively once your 90-day ' +
    'limit has been met. Some Medicare Supplement plans will cover an additional ' +
    '365 days after you use your Medicare Part A benefits.',
  TOOLTIP_MSG_MCARE_PART_B_COINSURANCE:
    'Your Medicare Part B coinsurance or co-pay is typically 20% of the total cost of ' +
    'Medicare-covered services. Some Medicare Supplement plans will cover this cost.',
  TOOLTIP_MSG_BLOOD_3_PINTS:
    'Some Medicare Supplement plans cover the full cost of the first three pints of blood used in a transfusion.',
  TOOLTIP_MSG_HOSPICE_CARE_COINSURANCE:
    'Medicare Part A will cover up to 95% of your hospice costs leaving you with a ' +
    '5% coinsurance. Some Medicare Supplement plans pay for this coinsurance as a ' +
    'covered benefit.',
  TOOLTIP_MSG_SKILLED_NURSING_FACILITY_COINSURANCE:
    'Medicare Part A will cover 100% of your SNF costs from day 1 -20. After this, you ' +
    'are responsible for daily co-pays. Some Medicare Supplement plans pay for ' +
    'these daily co-payments as a covered benefit.',
  TOOLTIP_MSG_MCARE_PART_A_DEDUCTIBLE:
    'Some Medicare Supplement plans will cover the full or partial Medicare Part A deductible.',
  TOOLTIP_MSG_MCARE_PART_B_DEDUCTIBLE:
    'Some Medicare Supplement plans will cover the full or partial Medicare Part B deductible.',
  TOOLTIP_MSG_MCARE_PART_B_EXCESS_CHARGES:
    'Excess charges are owed when a Medicare-accepting doctor does not approve ' +
    'Medicare Assignment. In this case, you could be charged a fee up to 15% on top ' +
    'of the approved amount for a service. Some Medicare Supplement plans cover ' +
    'this fee as a covered benefit.',
  TOOLTIP_MSG_FOREIGN_TRAVEL_EMERGENCY:
    'Original Medicare does not provide foreign health insurance coverage. Some ' +
    'Medicare Supplement plans offer foreign travel emergency coverage in the case ' +
    'of an emergency abroad.',
  TOOLTIP_MSG_MCARE_PART_B_PREVENTIVE_CARE:
    'In some cases, Original Medicare may require you to cover the coinsurance for ' +
    'preventive services. In this case, your Medicare Supplement plan may cover the ' +
    'cost of the coinsurance as a covered benefit.',
  NON_STD_COMPARE_PLANS_MSG:
    'Our agents will help you understand the available benefits',
  DISABLE_BUTTON_MSG: 'This will be enabled once your quotes are refreshed!',
  TOOLTIP_DENTAL_COVERAGE:
    'This coverage provides necessary dental benefits such as routine exams, x-rays, and dental surgery. ',
  TOOLTIP_VISION_COVERAGE:
    'This coverage provides necessary vision benefits such as routine exams, and coverage for glasses and contacts. ',
  TOOLTIP_HEARING_COVERAGE:
    ' This coverage provides necessary hearing coverage such as routine exams and coverage for hearing aids, replacements, and batteries.',
  TOOLTIP_CHAS:
    'This coverage provides monetary assistance in the event you receive a cancer, heart attack, or stroke diagnosis. ',
  TOOLTIP_HOSPITAL_INDEMNITY:
    'This coverage helps cover your daily inpatient copay up to a certain dollar amount when you are admitted to an inpatient facility.',
  TOOLTIP_ADD_PRESCRIPTION:
    'Input the name of your prescription drugs, dosage, and quantity to help better determine which Medicare plan fits your needs.',
  TOOLTIP_ADD_Physician:
    'Adding your doctor can help us in determining if they are covered in a Medicare plan, and in suggesting plans that offer maximum physician coverage.',
  TOOLTIP_ADD_Pharmacy:
    'Adding your pharmacy can help us in determining if they are covered in a Medicare plan, and in suggesting plans that offer pharmacy coverage.',

  MOBILE_CAROUSEL_SINGLE_PLAN_ADDITION: '1 plan added',
  TOOLTIP_AUTO_APPLY_INFOSTEPS:
    'Filters have been applied based on your current coverage. You can modify these anytime.',
  //For MA Plan Cards
  TOOLTIP_ESTIMATED_ANNUAL_COST: `Estimated Annual Cost (EAC) is the projected total amount a Medicare beneficiary
     may pay in a year, including premiums, deductibles, copayments, and out-of-pocket expenses for healthcare and medications.`,
  TOOLTIP_PRIMARY_PRIMARY_DOCTOR_CO_PAY: `Primary Doctor Co-Pay is a fixed fee a Medicare beneficiary pays for a primary care visit, varying by plan.`,
  TOOLTIP_SPECIALIST_CO_PAY: `Specialist Co-Pay is a fixed fee a Medicare beneficiary pays for visiting a specialist, which may be higher than a primary care co-pay and varies by plan.`,
  TOOLTIP_HOSPITAL_IN_PATIENT_CO_PAY: `Hospital Inpatient Co-Pay is the fixed amount a Medicare beneficiary pays for each hospital stay, which may vary based on the length of stay and plan coverage.`,
  TOOLTIP_PLAN_BENEFITS: `Plan Benefits are the healthcare services and coverage options included in a Medicare plan, such as doctor visits, hospital stays, and prescription drugs, which vary by plan.`,
  TOOLTIP_DOCTORS_COVERAGE: `Doctor Coverage refers to the doctors and specialists a Medicare plan covers, including network restrictions and out-of-pocket costs.`,
  TOOLTIP_PRESCRIPTION_COVERAGE: `Prescription Drug Coverage helps pay for medications costs like premiums, copays, and deductibles based on the plan's formulary.`,
  TOOLTIP_PHARMACY_COVERAGE: `Pharmacy refers to the locations or services where a Medicare beneficiary can fill prescriptions.`,

  TOOLTIP_COMPARE_DIFFERENCE: `Compare Differences refers to evaluating Medicare plans to identify variations in costs, coverage, provider networks, and additional benefits to find the best fit for individual healthcare needs.`,

  //For MS Cards
  TOOLTIP_MS_MONTHLY_PREMIUM:
    'Monthly Premium is the fixed amount a beneficiary pays each month for a Medicare Supplement (Medigap) plan.',
  TOOLTIP_MS_ONE_TIME_POLICY_FEE: `One-time Policy Fee is a single upfront charge some Medicare plans require when enrolling, covering administrative costs and not included in monthly premiums.`,

  TOOLTIP_ESTIMATED_ANNUAL_COST_WITH_DRUG_COVERAGE:
    'This is an estimate of the annual cost you could pay over the course of the year for your healthcare and ' +
    'drug coverage for Medicare Advantage and Medicare Part D plans. Estimated annual cost includes premiums, deductibles, copayments, and coinsurance for your selected plan. Your actual costs can vary.',
  TOOLTIP_DEFAULT_PHARMACY_MSG:
    'This pharmacy is added by default based on your area to provide you accurate plans and quotes in your area.',
  TOOLTIP_COMMON_DOSAGE_MSG: 'This is the most commonly selected dosage',
  TOOLTIP_NON_ELIGIBLE_STAR_RATING_MESSAGE:
    'This is a new plan and does not have ratings yet.',
  TOOLTIP_PHYSICIAN_LOCATION_MSG:
    'You can identify your doctor’s location by selecting a city or typing in their address below',
}

export default StringConstants
