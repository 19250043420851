import { BsChevronDown, BsChevronUp } from 'react-icons/bs'
import React, { useEffect, useRef, useState } from 'react'
import {
  addResponsesForQuestionnaires,
  getQuestionnaires,
} from '../../../../service/questionnaire'
import { isEmpty, isEqual, isNull, isUndefined } from 'lodash'

import BouncingDotsLoader from '../../../common/Loading/BouncingDotsLoader'
import CheckboxGroup from '../../../common/CheckboxGroup/CheckboxGroup.component'
import CloseIconButton from '../../../common/Button/CloseIconButton.component'
import DateField from '../../../common/DateField'
import { MSPlanRequestFilter } from '../../../../interface/quotes/mSupplementPlans/request/MSPlanRequestFilter'
import { MSPlansDisplayFilters } from '../../../../interface/quotes/mSupplementPlans/MSPlansDisplayFilters'
import OptionalMessage from '../../Common/OptionalMessageFilter'
import PaginationConstants from '../../../../constants/PaginationConstants'
import { QuestionnaireI } from '../../../../interface/questionnaire/QuestionnaireInterface'
import QuotesConstants from '../../../../constants/QuotesConstants'
import RouteConstants from '../../../../constants/RouteConstants'
import SliderComponent from '../../../common/Slider/Slider'
import { SnackbarTypes } from '../../../../enums/SnackbarTypesEnum'
import StringConstants from '../../../../constants/StringConstants'
import { Switch } from '@mui/material'
import Tooltip from '../../../common/Tooltip/Tooltip.component'
import { convertToUSCurrency } from '../../../../utils/CurrencyConverterUtil'
import customerStore from '../../../../datastore/CustomerStore'
import dayjs from 'dayjs'
import { fireEvent } from '../../../../cra'
import { getApiErrorMessage } from '../../../../utils/StringUtils'
import moment from 'moment'
import msPlanFiltersStore from '../../../../datastore/medicareQuotes/MSupplementPlanFiltersStore'
import { observer } from 'mobx-react'
import snackbarStore from '../../../../datastore/SnackbarStore'
import styled from '@emotion/styled'
import theme from '../../../../global/theme'
import { updateMSEffectiveDateFilter } from '../../../../service/customer'
import { useBurgerMenu } from '../../../../context/SidebarContext'

interface FilterContainerProps {
  showFilter?: boolean
  greyOut?: boolean
}

const FilterWrapper = styled.div`
  min-width: 350px;
  @media screen and (max-width: 950px) {
    min-width: 0px;
    width: 0px;
  }
`

const Container = styled.div<FilterContainerProps>`
  background-color: ${theme.colors.white};
  border-right: 1px solid ${theme.colors.borderColor};
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  z-index: 500;
  position: fixed;
  top: 0px;
  left: 0;
  height: 100%;
  width: 350px;
  padding: 100px 40px;
  overflow-y: auto;
  overflow-x: hidden;

  @media screen and (max-width: 950px) {
    max-height: 100vh;
    width: 320px;
    padding: 20px;
    z-index: 1001;
    display: ${({ showFilter }) => (showFilter ? 'block' : 'none')};
  }
  pointer-events: ${({ greyOut }) => (greyOut ? 'none' : 'auto')};
`

const Filter = styled.div`
  padding: 20px 0px;
`

const Label = styled.p`
  color: ${theme.colors.primary};
  font-size: 14px;
  font-weight: 700;
  padding-bottom: 8px;
`
const CloseButtonWrapper = styled.div`
  display: none;
  position: absolute;
  top: 10px;
  right: 0px;
  @media screen and (max-width: 950px) {
    display: block;
  }
`

const SliderContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const Overlay = styled.div<FilterContainerProps>`
  @media screen and (max-width: 950px) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    display: ${({ showFilter }) => (showFilter ? 'block' : 'none')};
    transition: background-color 0.3s ease;
  }
`

interface FilterSupplementProps {
  showFilter: boolean
  handleShowFilter: () => void
  filterRef: React.MutableRefObject<any>
  setSelectedFilters?: (data: string[]) => void
  msPlanFilters: MSPlansDisplayFilters
  setMSPlanFilter: (data: MSPlansDisplayFilters) => void
  handleRequestBodyChange: (
    fieldsChanged: MSPlanRequestFilter,
    forSpouse: boolean
  ) => void
  setPlanLoader: (data: boolean) => void
  fetchQuotesPullStatus: (planYear?: number) => void
  selectedFilters: string[]
}

interface HealthQuestionProps {
  id: string
  inputType: 'RADIO' | 'CHECKBOX'
  isRequired: boolean
  options: { option: string }[]
  question: string
  response: string[]
}

const FilterSupplement: React.FC<FilterSupplementProps> = ({
  showFilter,
  handleShowFilter,
  filterRef,
  setSelectedFilters,
  msPlanFilters,
  setMSPlanFilter,
  handleRequestBodyChange,
  setPlanLoader,
  fetchQuotesPullStatus,
  selectedFilters,
}) => {
  const msPlanRequestFilter: MSPlanRequestFilter = {} as MSPlanRequestFilter
  const [tobaccoConsumption, setTobaccoConsumption] = useState<boolean>(false)
  const [carrierDropdown, setCarrierDropdown] = useState(false)
  const [typeDropdown, setTypeDropdown] = useState(false)
  const [ambestDropdown, setAmbestDropdown] = useState(false)
  const [monthlyPremiumSliderValue, setMonthlyPremiumSliderValue] =
    useState<number>(msPlanFilters?.monthlyPremium.currentValue)
  const [tobaccoConsumptionQuestion, setTobaccoConsumptionQuestion] =
    useState<HealthQuestionProps>({} as HealthQuestionProps)
  const [loading, setLoading] = useState(false)

  //For mobile view to hide the Filter section when clicked outside
  const filterSectionRef = useRef<HTMLDivElement>(null)
  const { toggleSidebar, isOpen } = useBurgerMenu()
  React.useEffect(() => {
    // Only add listener if sidebar is open
    if (isOpen) {
      const handleClickOutside = (event: any) => {
        if (
          filterSectionRef.current &&
          !filterSectionRef.current?.contains(event.target)
        ) {
          toggleSidebar()
        }
      }

      document.addEventListener('mousedown', handleClickOutside)

      // Cleanup listener on unmount or when sidebar closes
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }
  }, [isOpen])

  useEffect(() => {
    setTobaccoConsumption(customerStore.consumesTobacco)

    getQuestionnaires(msPlanFiltersStore.isPrimaryAndSpouseOption, 'HEALTH')
      .then((res: QuestionnaireI[]) => {
        const healthQuestion = res.find(
          (x) => x.question === 'Have you used tobacco in the last 12 months?'
        )
        const response =
          (healthQuestion?.responses &&
            healthQuestion.responses.length > 0 &&
            healthQuestion.responses[0] &&
            healthQuestion.responses[0].response) ||
          []
        const tempTobaccoConsumption = response.includes('Yes') ? true : false
        if (healthQuestion)
          setTobaccoConsumptionQuestion({
            id: healthQuestion.id,
            inputType: healthQuestion.inputType,
            isRequired: healthQuestion.isRequired,
            options: healthQuestion.options,
            question: healthQuestion.question,
            response: response,
          } as HealthQuestionProps)
        if (
          isEmpty(msPlanFilters) &&
          !isUndefined(msPlanFiltersStore.getMSPlansFilters())
        ) {
          setTobaccoConsumption(tempTobaccoConsumption)
        } else {
          setTobaccoConsumption(tempTobaccoConsumption)
        }
        customerStore.setConsumesTobacco(tempTobaccoConsumption)
      })
      .catch(() => {
        snackbarStore.set({
          snackbarMessage: getApiErrorMessage(
            'fetch tobacco consumption preference'
          ),
          snackbarOpen: true,
          snackbarType: 'error',
        })
      })
  }, [msPlanFilters])

  const handleSelectedFilters = (value: string) => {
    if (setSelectedFilters) {
      const updatedFilters = selectedFilters.includes(value)
        ? selectedFilters.filter((filter) => filter !== value)
        : [...selectedFilters, value]
      setSelectedFilters(updatedFilters)
      msPlanFiltersStore.setSelectedFilters(
        updatedFilters,
        msPlanFiltersStore.isPrimaryAndSpouseOption
      )
    }
  }

  const handleTobaccoConsumption = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setLoading(true)
    customerStore.setConsumesTobacco(!customerStore.consumesTobacco)
    setTobaccoConsumption(event.target.checked)
    if (msPlanFilters) {
      const response = event.target.checked === true ? ['Yes'] : ['No']
      const data: any = !isEmpty(tobaccoConsumptionQuestion)
        ? [{ ...tobaccoConsumptionQuestion, response }]
        : []
      if (!isEmpty(data)) {
        addResponsesForQuestionnaires(
          msPlanFiltersStore.isPrimaryAndSpouseOption,
          data
        )
          .then(() => {
            customerStore.setConsumesTobacco(event.target.checked)
            msPlanFiltersStore.setIsQuotesPullStatusFetchTriggered(true)
            msPlanFiltersStore.setIsTobaccoChanged(true)
            snackbarStore.set({
              snackbarMessage: StringConstants.SNACKBAR_SAVE_SUCCESS_MSG,
              snackbarOpen: true,
              snackbarType: 'success',
            })
          })
          .catch((err) => {
            setTobaccoConsumption(!event.target.checked)
            snackbarStore.set({
              snackbarMessage: 'Unable to save tobacco consumption preference',
              snackbarOpen: true,
              snackbarType: 'error',
            })
          })
          .finally(() => {
            setLoading(false)
          })
      } else {
        setLoading(false)
      }
    }
  }

  const handlePlanTypeCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (msPlanFilters) {
      const checkedPlanTypes = msPlanFilters.planTypes?.map((item) =>
        item.value === e.target.value
          ? { ...item, checked: !item.checked }
          : item
      )
      setMSPlanFilter({
        ...msPlanFilters,
        planTypes: checkedPlanTypes,
      })

      handleSelectedFilters(e.target.value)
      handleRequestBodyChange(
        {
          planTypes: checkedPlanTypes?.reduce((acc, item) => {
            if (item.checked) {
              acc.push(item.value)
            }
            return acc
          }, [] as string[]),
          paginationDetail: {
            page: PaginationConstants.DEFAULT_PAGE,
            size: PaginationConstants.DEFAULT_PAGE_SIZE,
          },
        },
        msPlanFiltersStore.isPrimaryAndSpouseOption
      )

      if (!msPlanFiltersStore.isPrimaryAndSpouseOption) {
        msPlanFiltersStore.msPlanFilters.planTypes = checkedPlanTypes
      } else {
        msPlanFiltersStore.spouseFilters.planTypes = checkedPlanTypes
      }
      setPlanLoader(true)
    }
  }

  const handleCarrierCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (msPlanFilters) {
      const checkedCarriers = msPlanFilters.carriers?.map((item) =>
        item.value === e.target.value
          ? { ...item, checked: !item.checked }
          : item
      )
      setMSPlanFilter({
        ...msPlanFilters,
        carriers: checkedCarriers,
      })

      handleSelectedFilters(e.target.value)

      handleRequestBodyChange(
        {
          carriers: checkedCarriers?.reduce((acc, item) => {
            if (item.checked) {
              acc.push(item.value)
            }
            return acc
          }, [] as string[]),
          paginationDetail: {
            page: PaginationConstants.DEFAULT_PAGE,
            size: PaginationConstants.DEFAULT_PAGE_SIZE,
          },
        },
        msPlanFiltersStore.isPrimaryAndSpouseOption
      )

      if (msPlanFiltersStore.isPrimaryAndSpouseOption) {
        msPlanFiltersStore.spouseFilters.carriers = checkedCarriers
      } else {
        msPlanFiltersStore.msPlanFilters.carriers = checkedCarriers
      }
      setPlanLoader(true)
    }
  }

  const handleAMBestRatingChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (msPlanFilters) {
      const checkedAMBestRatings = msPlanFilters.ambestRatings?.map((item) =>
        item.value === e.target.value
          ? { ...item, checked: !item.checked }
          : item
      )
      setMSPlanFilter({
        ...msPlanFilters,
        ambestRatings: checkedAMBestRatings,
      })
      handleSelectedFilters(e.target.value)
      handleRequestBodyChange(
        {
          ambestRatings: checkedAMBestRatings?.reduce((acc, item) => {
            if (item.checked) {
              acc.push(item.value)
            }
            return acc
          }, [] as string[]),
          paginationDetail: {
            page: PaginationConstants.DEFAULT_PAGE,
            size: PaginationConstants.DEFAULT_PAGE_SIZE,
          },
        },
        msPlanFiltersStore.isPrimaryAndSpouseOption
      )

      if (msPlanFiltersStore.isPrimaryAndSpouseOption) {
        msPlanFiltersStore.spouseFilters.ambestRatings = checkedAMBestRatings
      } else {
        msPlanFiltersStore.msPlanFilters.ambestRatings = checkedAMBestRatings
      }
      setPlanLoader(true)
    }
  }

  const updateMSEffectiveDateFilterSelf = (
    msEffectiveDateFilterSelf: string
  ) => {
    msPlanFiltersStore.setIsOverLayLoading(true)
    updateMSEffectiveDateFilter(msEffectiveDateFilterSelf)
      .then(() => {
        customerStore.isMSQuotesProcessing = true
        fetchQuotesPullStatus()
        customerStore.setMSEffectiveDateFilterSelf(msEffectiveDateFilterSelf)
        snackbarStore.set({
          snackbarMessage: StringConstants.SNACKBAR_SAVE_SUCCESS_MSG,
          snackbarOpen: true,
          snackbarType: SnackbarTypes.SUCCESS,
        })
      })
      .catch(() => {
        snackbarStore.set({
          snackbarMessage: getApiErrorMessage('update customer details'),
          snackbarOpen: true,
          snackbarType: SnackbarTypes.ERROR,
        })
      })
      .finally(() => {
        msPlanFiltersStore.setIsOverLayLoading(false)
      })
  }

  useEffect(() => {
    const startOfNextMonth =
      moment().add(1, 'month').toISOString().split('T')[0].substring(0, 8) +
      '01'
    if (!isEmpty(msPlanFiltersStore.effectiveDate)) {
      if (validateDate()) {
        if (
          msPlanFiltersStore.effectiveDate !==
          customerStore.msEffectiveDateFilterSelf!.split('T')[0]
        ) {
          if (isEmpty(msPlanFiltersStore.effectiveDate)) {
            updateMSEffectiveDateFilterSelf(
              isEmpty(customerStore.currentMedicarePartBDateSelf)
                ? startOfNextMonth
                : customerStore.currentMedicarePartBDateSelf!.split('T')[0]
            )
          } else {
            updateMSEffectiveDateFilterSelf(msPlanFiltersStore.effectiveDate)
          }
        }
      } else {
        snackbarStore.set({
          snackbarMessage: 'The selected date is wrong or invalid',
          snackbarOpen: true,
          snackbarType: 'error',
        })
      }
    }
  }, [msPlanFiltersStore.effectiveDate])

  const handleDateChange = (e: any) => {
    if (e) {
      msPlanFiltersStore.setTempEffectiveDate(dayjs(e.$d).format('YYYY-MM-DD'))
    }
  }

  const handleMaxMonthlyPremium = (e: any) => {
    if (msPlanFilters) {
      const mpValue = {
        ...msPlanFilters.monthlyPremium,
        currentValue: monthlyPremiumSliderValue,
      }
      setMSPlanFilter({
        ...msPlanFilters,
        monthlyPremium: mpValue,
      })
      msPlanFiltersStore.setIsFilterChanged(true)

      handleRequestBodyChange(
        {
          monthlyPremium: {
            start: mpValue.minValue,
            end: mpValue.currentValue,
          },
        },
        msPlanFiltersStore.isPrimaryAndSpouseOption
      )
      setPlanLoader(true)

      if (msPlanFiltersStore.isPrimaryAndSpouseOption) {
        msPlanFiltersStore.spouseFilters.monthlyPremium = mpValue
      } else {
        msPlanFiltersStore.msPlanFilters.monthlyPremium = mpValue
      }
    }
  }

  const validateDate = () => {
    if (msPlanFiltersStore.effectiveDate === '') return true
    if (
      parseInt(msPlanFiltersStore.effectiveDate.split('-')[0]) <=
      moment().year() + 5
    )
      return true
    else return false
  }

  const applyFilters = () => {
    if (!isEmpty(msPlanFilters)) {
      msPlanRequestFilter.carriers = msPlanFilters.carriers
        ?.filter((item) => item.checked)
        .map((item) => item.value)

      msPlanRequestFilter.ambestRatings = msPlanFilters.ambestRatings
        ?.filter((item) => item.checked)
        .map((item) => item.value)

      msPlanRequestFilter.planTypes = msPlanFilters.planTypes
        ?.filter((item) => item.checked)
        .map((item) => item.value)

      if (
        msPlanFilters.annualDeductible.currentValue !==
        msPlanFilters.annualDeductible.maxValue
      ) {
        msPlanRequestFilter.annualDeductible = {
          start: msPlanFilters.annualDeductible.minValue,
          end: msPlanFilters.annualDeductible.currentValue,
        }
      }

      // if (
      //   msPlanFilters.estimatedAnnualCost.currentValue !==
      //   msPlanFilters.estimatedAnnualCost.maxValue
      // ) {
      //   msPlanRequestFilter.estimatedAnnualCost = {
      //     start: msPlanFilters.estimatedAnnualCost.minValue,
      //     end: msPlanFilters.estimatedAnnualCost.currentValue,
      //   }
      // }

      if (
        msPlanFilters.monthlyPremium.currentValue !==
        msPlanFilters.monthlyPremium.maxValue
      ) {
        msPlanRequestFilter.monthlyPremium = {
          start: Math.trunc(msPlanFilters.monthlyPremium.minValue * 100),
          end: Math.trunc(msPlanFilters.monthlyPremium.currentValue * 100),
        }
      }

      if (
        !isNull(msPlanFiltersStore.msPlanFilters.selectedSortOption) &&
        msPlanFiltersStore.msPlanFilters.selectedSortOption !== ''
      ) {
        msPlanRequestFilter.sortDetail = {
          order: 'ASC',
          type: msPlanFiltersStore.msPlanFilters.selectedSortOption,
        }
      }

      if (
        !isEqual(
          JSON.stringify(msPlanRequestFilter),
          JSON.stringify(msPlanFiltersStore.getMSPlanRequestFilters())
        )
      ) {
        msPlanFilters.selectedSortOption =
          msPlanFiltersStore.msPlanFilters.selectedSortOption
        msPlanFiltersStore.setFilterApplied(true)
        msPlanFiltersStore.setMSPlansFilters(msPlanFilters)
        msPlanRequestFilter.paginationDetail = {
          page: PaginationConstants.DEFAULT_PAGE,
          size: PaginationConstants.DEFAULT_PAGE_SIZE,
        }
        customerStore.setMSPageSize(PaginationConstants.DEFAULT_PAGE_SIZE)
        msPlanFiltersStore.setMSPlanRequestFilters(msPlanRequestFilter)
        handleShowFilter()
      }

      msPlanFiltersStore.setMSPlanRequestFilters(msPlanRequestFilter)
      handleShowFilter()
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }

  const clearFilters = () => {
    if (msPlanFilters) {
      setMonthlyPremiumSliderValue(msPlanFilters.monthlyPremium.maxValue)
      setMSPlanFilter({
        ...msPlanFilters,
        annualDeductible: {
          ...msPlanFilters.annualDeductible,
          currentValue: msPlanFilters.annualDeductible.maxValue,
        },
        carriers: msPlanFilters.carriers.map((item) => ({
          ...item,
          checked: false,
        })),
        // currentPlanYear: '',
        monthlyPremium: {
          ...msPlanFilters.monthlyPremium,
          currentValue: msPlanFilters.monthlyPremium.maxValue,
        },
        // estimatedAnnualCost: {
        //   ...msPlanFilters.estimatedAnnualCost,
        //   currentValue: msPlanFilters.estimatedAnnualCost.maxValue,
        // },
        planTypes: msPlanFilters.planTypes.map((item) => ({
          ...item,
          checked: false,
        })),
        ambestRatings: msPlanFilters.ambestRatings.map((item) => ({
          ...item,
          checked: false,
        })),
        clearFiltersApplied: true,
        selectedSortOption: QuotesConstants.initialMSPlanSortOption,
      })
      setSelectedFilters && setSelectedFilters([])
    }
  }

  const handleDropdownClick = (id: string) => {
    if (id === 'carrier') {
      setCarrierDropdown(!carrierDropdown)
      setTypeDropdown(false)
      setAmbestDropdown(false)
    }
    if (id === 'type') {
      setTypeDropdown(!typeDropdown)
      setCarrierDropdown(false)
      setAmbestDropdown(false)
    }
    if (id === 'ambest') {
      setAmbestDropdown(!ambestDropdown)
      setCarrierDropdown(false)
      setTypeDropdown(false)
    }
  }

  return (
    <FilterWrapper ref={filterRef}>
      <Overlay showFilter={showFilter} />
      <Container
        ref={filterSectionRef}
        greyOut={msPlanFiltersStore.filterApplied}
        showFilter={showFilter}
      >
        <CloseButtonWrapper>
          <CloseIconButton
            fontSize='32px'
            onClick={() => {
              handleShowFilter()
            }}
          />
        </CloseButtonWrapper>
        {!isEmpty(msPlanFilters) ? (
          <div>
            <OptionalMessage
              heading='Showing plans for'
              link={RouteConstants.PROFILE + '/myprofile/contact'}
              linkText={customerStore.getPostalCodeAndCounty()}
              showToolTip={true}
            />
            <div className='filters-wrapper'>
              <Filter>
                <Label>
                  Tobacco Usage
                  <Tooltip
                    title={StringConstants.TOOLTIP_MSG_MS_TOBACCO_CONSUMPTION}
                  />
                </Label>
                {loading ? (
                  <BouncingDotsLoader />
                ) : (
                  <>
                    <span
                      style={{
                        color: `${theme.colors.textGrey}`,
                        fontSize: '14px',
                      }}
                    >
                      No
                    </span>
                    <Switch
                      checked={tobaccoConsumption}
                      onChange={(e) => {
                        fireEvent('switchInput', e, {
                          label: 'Tobacco Consumption',
                          value: tobaccoConsumption ? 'No' : 'Yes',
                          description:
                            'Rx Coverage Included/Not Included Filter - Medicare Advantage',
                        })
                        handleTobaccoConsumption(e)
                      }}
                    />
                    <span
                      style={{
                        color: `${theme.colors.textGrey}`,
                        fontSize: '14px',
                      }}
                    >
                      Yes
                    </span>
                  </>
                )}
              </Filter>
              <Filter>
                <Label>
                  Effective Date{' '}
                  <Tooltip
                    title={StringConstants.TOOLTIP_MSG_MS_PART_B_EFFECTIVE_DATE}
                  />
                </Label>
                <DateField
                  format='MM/DD/YYYY'
                  value={dayjs(
                    isEmpty(msPlanFiltersStore.tempEffectiveDate)
                      ? isEmpty(msPlanFiltersStore.effectiveDate)
                        ? isEmpty(customerStore.currentMedicarePartBDateSelf)
                          ? moment()
                              .add(1, 'month')
                              .toISOString()
                              .split('T')[0]
                              .substring(0, 8) + '01'
                          : customerStore.currentMedicarePartBDateSelf
                              ?.toString()
                              .split('T')[0]
                        : msPlanFiltersStore.effectiveDate
                      : isEmpty(msPlanFiltersStore.tempEffectiveDate)
                      ? isEmpty(customerStore.currentMedicarePartBDateSelf)
                        ? moment()
                            .add(1, 'month')
                            .toISOString()
                            .split('T')[0]
                            .substring(0, 8) + '01'
                        : customerStore.currentMedicarePartBDateSelf
                            ?.toString()
                            .split('T')[0]
                      : msPlanFiltersStore.tempEffectiveDate
                  )}
                  minDate={dayjs(
                    moment()
                      .add(1, 'month')
                      .toISOString()
                      .split('T')[0]
                      .substring(0, 8) + '01'
                  )}
                  maxDate={dayjs(
                    moment().add(1, 'year').year().toString() + '-12-31'
                  )}
                  disablePast={true}
                  onAccept={handleDateChange}
                />
              </Filter>
              {msPlanFilters.planTypes && (
                <Filter>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      handleDropdownClick('type')
                    }}
                  >
                    <Label>
                      Plan Type{' '}
                      <Tooltip
                        title={StringConstants.TOOLTIP_MSG_MS_PLAN_TYPES}
                      />
                    </Label>
                    {typeDropdown ? (
                      <BsChevronUp
                        style={{ cursor: 'pointer', marginLeft: 'auto' }}
                      />
                    ) : (
                      <BsChevronDown
                        style={{ cursor: 'pointer', marginLeft: 'auto' }}
                      />
                    )}
                  </div>
                  {typeDropdown ? (
                    <div>
                      <CheckboxGroup
                        checkboxState={msPlanFilters.planTypes}
                        onChange={handlePlanTypeCheckboxChange}
                        width='100%'
                        type='unstyled'
                        checkboxGroupName='Plan Type Filter - Medicare Supplement'
                      />
                    </div>
                  ) : null}
                </Filter>
              )}
              {msPlanFilters.carriers && (
                <Filter>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      handleDropdownClick('carrier')
                    }}
                  >
                    <Label>
                      Carrier
                      <Tooltip
                        title={StringConstants.TOOLTIP_MSG_MS_CARRIERS}
                      />
                    </Label>
                    {carrierDropdown ? (
                      <BsChevronUp
                        style={{ cursor: 'pointer', marginLeft: 'auto' }}
                      />
                    ) : (
                      <BsChevronDown
                        style={{ cursor: 'pointer', marginLeft: 'auto' }}
                      />
                    )}
                  </div>
                  {carrierDropdown ? (
                    <div>
                      <CheckboxGroup
                        checkboxState={msPlanFilters.carriers}
                        onChange={handleCarrierCheckboxChange}
                        width='100%'
                        type='unstyled'
                        checkboxGroupName='Carrier Filter - Medicare Supplement'
                      />
                    </div>
                  ) : null}
                </Filter>
              )}
              {msPlanFilters.ambestRatings && (
                <Filter>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      handleDropdownClick('ambest')
                    }}
                  >
                    <Label>
                      AM Best Rating
                      <Tooltip
                        title={StringConstants.TOOLTIP_MSG_MS_AM_BEST_RATING}
                      />
                    </Label>
                    {ambestDropdown ? (
                      <BsChevronUp
                        style={{ cursor: 'pointer', marginLeft: 'auto' }}
                      />
                    ) : (
                      <BsChevronDown
                        style={{ cursor: 'pointer', marginLeft: 'auto' }}
                      />
                    )}
                  </div>
                  {ambestDropdown ? (
                    <div>
                      <CheckboxGroup
                        checkboxState={msPlanFilters.ambestRatings}
                        onChange={handleAMBestRatingChange}
                        width='100%'
                        type='unstyled'
                        checkboxGroupName='AM Best Rating Filter - Medicare Supplement'
                      />
                    </div>
                  ) : null}
                </Filter>
              )}
              {msPlanFilters.monthlyPremium && (
                <Filter>
                  <SliderComponent
                    label='Maximum Monthly Premium'
                    minValue={msPlanFilters.monthlyPremium.minValue}
                    maxValue={msPlanFilters.monthlyPremium.maxValue}
                    step={msPlanFilters.monthlyPremium.stepValue}
                    size='small'
                    value={monthlyPremiumSliderValue}
                    onChange={(e: any) => {
                      setMonthlyPremiumSliderValue(e.target.value)
                    }}
                    onChangeCommitted={(e: any) => {
                      handleMaxMonthlyPremium(e)
                      const label = 'Maximum Monthly Premium'
                      const value = monthlyPremiumSliderValue
                      fireEvent('sliderInput', e, {
                        label,
                        value,
                        description: `Value of ${label} set to ${value}`,
                      })
                    }}
                    withTooltip={true}
                    tooltipContent={
                      StringConstants.TOOLTIP_MSG_MAXIMUM_MONTHLY_PREMIUM
                    }
                  />
                  <SliderContentContainer>
                    <div>
                      {convertToUSCurrency(
                        msPlanFilters.monthlyPremium.minValue
                      )}
                    </div>
                    <div>
                      {convertToUSCurrency(
                        msPlanFilters.monthlyPremium.maxValue
                      )}
                    </div>
                  </SliderContentContainer>
                </Filter>
              )}
            </div>
          </div>
        ) : null}
      </Container>
    </FilterWrapper>
  )
}

export default observer(FilterSupplement)
